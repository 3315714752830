import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {getProductTreads} from "./apiTreads";
import {baseUrlImages} from '../config';
import { setCart, removeCart, getCart, existsInCart } from "../Quoter/quoterProvider";
import { getDatabooksPdf } from "../Databook/apiDatabooks";
import {Helmet} from 'react-helmet';

const Tread = () => {
  
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [mesures, setMesures] = useState([]);
  const [mesurePicked, setMesurePicked] = useState({});
  let product = {characteristics: [], treads: [], information: []};
  if(products.length) {
    product = products[0];
  }
  const fetchProducts = async () => {
    const fetchedProducts = await getProductTreads({id});
    setProducts(fetchedProducts)
  }
  const downloadPdf = async () => {
    const ids = mesures.map((mesure) => mesure.id)
    await getDatabooksPdf(ids , product.description);
  }
  const pushMesure = () => {
    const exists = mesures.find(mesure => mesure.id === mesurePicked.id);
    const newMesures = [...mesures];
    if(!exists) {
      newMesures.push(mesurePicked);
      setMesures(newMesures);
    }
  }
  const removeMesure = (id) => {
    const newMesures = mesures.filter(mesure => mesure.id !== id);
      setMesures(newMesures);
  }
  const setMesure = (mesureId) => {
    if(product && product.treads.length > 0) {
      const mesure = product.treads.find(mesure => mesure.id.toString() === mesureId.toString())
      setMesurePicked(mesure);
    }
  }

  useEffect(() => {
    fetchProducts();
}, []);
  return (
    <div>
      <Helmet>
          <title>TruckTire - {product?.description || ''}</title>
      </Helmet>
    <div className="container-page">
    <div className="header-form">
    <div className="title-icon-continental">
                <img src={`/img/icon_${product.brand_id}.jpg`} className="img-responsive" alt="Cart"/>
                <span>{product.description}</span>
            </div>
            <button onClick={() => history(-1)}className="btn-action border-100 button-init small-icon">
                <span>Cerrar</span>
                <img src="/img/icons/icon_close.svg" className="img-responsive" alt="Cart"/>
            </button>
    </div>
    <div className="container-fluid container-information scroll-object">
    <div>
        <div className="row">
            <div className="col-12 col-sm-7">
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Características</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                      {product.information.map((characteristic) => {
                        return(<p key={characteristic.id}><strong>&#x26AC;</strong> {characteristic.description}</p>)
                      })}

                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Aplicaciones</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <p>
                        {product.application_detail}
                    </p>
                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Generar ficha técnica</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="container-input margin-top-input">
                        <select onChange={(event) => setMesure(event.target.value)} className="input-class text-center">
                            <option>*Medida</option>
                            {product?.treads?.map((databook) => {
                              return(<option key={databook.id} value={databook.id}>Ancho: {databook.width} - Profundidad: {databook.depth} ({databook.code})</option>)
                            })}
                        </select>
                        <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                    </div>
                    <div className="content-modal-button">
                        <button onClick={() => pushMesure()} className="btn-action border-100 button-init">
                            <span>Seleccionar llanta</span>
                            <img src="/img/icons/icon_plus.svg" className="img-responsive" alt="Cart"/>
                        </button>
                    </div>
                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Medidas seleccionadas</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    {mesures.map((mesure) => {
                      mesure.productDescription = product.description;
                      mesure.productPicture = product.picture;
                      mesure.product = {type: product.type};
                      return(
                        <div key={mesure.id} className="content-add-cart">
                        <p>Ancho: {mesure.width} - Profundidad: {mesure.depth} ({mesure.code})</p>
                        <div className="icons-cart">
                            {!existsInCart(mesure.id) && <a onClick={() => setCart(mesure)} href="#"><img src="/img/icons/icon_cart_plus_dark.svg" className="img-responsive" alt="Cart"/></a>}
                            <a href="#" onClick={() => removeMesure(mesure.id)}><img src="/img/icons/icon_close_red.svg" className="img-responsive" alt="remove"/></a>
                        </div>
                    </div>
                      )
                    })
                    }
                    <div className="content-modal-button">
                        <button onClick={() => downloadPdf()} className="btn-action border-100 button-init">
                            <span>Descargar ficha técnica</span>
                            <img src="/img/icons/icon_download.svg" className="img-responsive" alt="Cart"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-5">

                <div className="img-tire img-tire-modal">
                    <img src={`${baseUrlImages}/${product.picture}`} alt="" className="img-responsive" />
                </div>
              


            </div>
        </div>
    </div>
    </div>
  </div>
  </div>
  );
}
export {Tread};