import React, {useEffect, useState} from "react";
import {useAuth} from '../Auth/authContext';
import {
  Link, useLocation
} from "react-router-dom";
import { getCart } from "../Quoter/quoterProvider";
const Header = () => {
  const {pathname} = useLocation();
  const auth = useAuth();
  const [cart, setCart] = useState(getCart());
  const [menuStatus, setMenuStatus] = useState(false);
  const activeInactiveMenu = () => {
    setMenuStatus(!menuStatus);
  }
  useEffect(() => {
    setTimeout(() => {
      const newCart = getCart();
      setCart(newCart);
  }, 1000)
}, [cart, auth.user]);

  return (
    <header>
      <Link to="/home" className="iconhome"><img src="/img/truck_tire_logo.png" className="img-responsive" alt="Truck Tire Continental"/></Link>
      {auth.user && (      <div className="content-buttons">
      <Link to="/fleets" className="iconfleet">
          <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets"/>
        </Link>
      {pathname !== '/quoter' &&
      (<Link to="/quoter" className="iconcart">
          <img src="/img/icons/icon_cart.svg" className="img-responsive" alt="Cart"/>
          {cart.length > 0 && 
            <span className="numbercart">{cart.length}</span>
          }
        </Link>)}
        {pathname === '/quoter' &&
      (<Link to="/home" className="btn-close-cotizador">
          <img src="/img/icons/close_menu.svg" className="img-responsive" alt="Cart"/>
        </Link>)}
        <div className={` ${menuStatus ? 'active': ''}`} onClick={() => activeInactiveMenu()} id="menu-page"><span>&nbsp;</span></div>
        <button         onClick={() => auth.signout() } className="btn-action border-100 button-init button-close-session">
            <span>Cerrar sesi&oacute;n</span>
            <img src="/img/icons/icon_sesion.svg" className="img-responsive" alt="Cart"/>
        </button>
        <div className={`modal-background container-menu ${menuStatus ? 'active': ''}`}>
          <div className={`menu-continental ${menuStatus ? 'active': ''}`}>
              <Link to="/tireselector" className="button-menu">
                <img src="/img/icons/icon_tireselector.svg" className="img-responsive"  alt=""/>
                <span>TireSelector</span>
              </Link>
              <Link to="/products" className="button-menu">
                <img src="/img/icons/icon_fichatecnica.svg" className="img-responsive"  alt=""/>
                <span>Ficha T&eacute;cnica</span>
              </Link>
              <Link to="/quoter" className="button-menu">
                <img src="/img/icons/icon_cotizador.svg" className="img-responsive"  alt=""/>
                <span>Cotizador</span>
              </Link>
              <Link to="/support" className="button-menu">
                <img src="/img/icons/icon_support.svg" className="img-responsive"  alt=""/>
                <span>Technical Support</span>
              </Link>
              <Link to="/tips" className="button-menu">
                <img src="/img/icons/icon_contitips.svg" className="img-responsive"  alt=""/>
                <span>ContiTips</span>
              </Link>
              <Link to="/news" className="button-menu">
                <img src="/img/icons/icon_continews.svg" className="img-responsive"  alt=""/>
                <span>ContiNews</span>
              </Link>
            </div>
        </div>
      </div>)}
    </header>
  );
}
export {Header};