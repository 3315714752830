import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {getFleets} from './apiFleets'
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import {baseUrlImages} from '../config';
import {createReviews} from '../InspectionsReview/apiInspectionsReviews'
import {Search} from '../Search/Search';
const Fleets = () => {
  const history = useNavigate();

  const [fleets, setFleets] = useState([]);
  const [originalFleets, setOriginalFleets] = useState([]);
  const fetchFleets = async () => {
    const fetchedFleets = await getFleets();
    setFleets(fetchedFleets);
    setOriginalFleets(fetchedFleets);
  }
  useEffect(() => {
    fetchFleets();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Mis Flotas</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>Mis Flotas</span>
        </div>
        <div className="header-callaction search-products">
            <Search products={fleets} originalProducts={[...originalFleets]} filterProducts={setFleets} />
            <button onClick={() => history(-1)} className="close-header">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
                <div className="container-tires ContiTips">
                  { fleets.map((fleet, key) => {
                    return(
                    <div key={key} className="container-tips col-12 col-sm-6 col-md-4">
                      <div className="img-tip">
                          <img src={`${baseUrlImages}/${fleet.picture || 'fleets.jpeg'}`} alt="" className="img-responsive" />
                      </div>
                      <div className="content-tip-text">
                      <button onClick={() => createReviews({cvt_inspections_fleet_id: fleet.id})} className="download-button" >
                            <img src="/img/icons/icon_download.svg" alt="close" />
                        </button>
                          <div className="information">
                              <h4>{fleet.description}</h4>
                          </div>
                          <div className="content-modal-button">
                            <Link to={`/fleet/${fleet.id}`}>
                              <button className="btn-action border-100 button-init small-icon">
                                  <span>Ver vehículos</span>
                                  <img src="./img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                              </button>
                            </Link>
                          </div>
                      </div>
                    </div>
                  )})
                    }
                    <div className="container-tips content-tip-add">
                    <Link className="width-100" to={`/createfleet`}>
                      <div className="add-new"><div className="add-container">+</div></div>
                      </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {Fleets};