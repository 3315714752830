import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {getContents} from "../Content/apiContent";
import {baseUrlImages} from '../config';
import { useNavigate } from "react-router-dom";
import {Helmet} from 'react-helmet';

const Support = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route)
  }
  const [contents, setContents] = useState([]);

  const fetchContent = async () => {
    const fetchedContents = await getContents({type: 'SUPPORT'});
    setContents(fetchedContents)
  }
  useEffect(() => {
    fetchContent();
}, []);
  return (
    <div className="container-page">
            <Helmet>
          <title>TruckTire - Technical Support</title>
      </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="./img/icons/icon_support_white.svg" className="img-responsive" alt="Cart"/>
            <span>Technical Support</span>
        </div>
        <div className="header-callaction">
        <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
                <img src="./img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
                <div className="container-tires">
                  { contents.map((content) => {
                    return(
                    <div className="container-tips col-12 col-sm-6 col-md-4">
                
                        <div className="img-tip">
                            <img src={`${baseUrlImages}/${content.picture}`} alt="" className="img-responsive" />
                        </div>
                        <div className="content-tip-text">
                            <div className="information">
                                <h4>{content.title}</h4>
                                <p>{content.description}</p>
                            </div>
                            <div className="content-modal-button">
                              <Link to={`/supportdetail/${content.id}`}>
                                <button className="btn-action border-100 button-init small-icon">
                                    <span>Ver m&aacute;s</span>
                                    <img src="./img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                                </button>
                              </Link>
                            </div>
                        </div>
                    </div>)
                  })}
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {Support};