import React, {useEffect, useState} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {getFleets} from './apiFleets';
import {getInspectionsVehicles} from '../InspectionsVehicle/apiInspectionsVehicles';
import {Helmet} from 'react-helmet';
import {baseUrlImages} from '../config';
import {createReviews} from '../InspectionsReview/apiInspectionsReviews'
import {Search} from '../Search/Search';

const Fleet = () => {
  const history = useNavigate();

  const [fleet, setFleet] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]);
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const fetchFleet = async () => {

    const fetchedFleets = await getFleets({id});
    setFleet(fetchedFleets[0])
  }
  const fetchVehicles = async () => {

    const fetchedVehicles = await getInspectionsVehicles({cvt_inspections_fleet_id: id});
    setVehicles(fetchedVehicles)
    setOriginalVehicles(fetchedVehicles)
  }
  useEffect(() => {
    fetchFleet();
    fetchVehicles();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Flota - {fleet.description || ''}</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>{fleet.description || ''}</span>
        </div>
        <div className="header-callaction search-products">
            <Search products={vehicles} originalProducts={[...originalVehicles]} filterProducts={setVehicles} />
            <button onClick={() => history(-1)} className="close-header">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
                <div className="container-tires ContiTips">
                  { vehicles.map((vehicle, key) => {
                    return(
                    <div key={key} className="container-tips col-12 col-sm-6 col-md-4">
                      <div className="img-tip">
                          <img src={`${baseUrlImages}/config_cvt_inspections_vehicles/${vehicle.cvt_inspections_configuration.description}.png`} alt="" className="img-responsive" />
                      </div>
                      <div className="content-tip-text">
                        <button onClick={() => createReviews({cvt_inspections_vehicle_id: vehicle.id})} className="download-button" >
                            <img src="/img/icons/icon_download.svg" alt="close" />
                        </button>
                          <div className="information">
                              <h4>{vehicle.license_plate}</h4>
                              <p>{vehicle.cvt_inspections_vehicle_brand.description} </p>
                              <p>{vehicle.cvt_inspections_configuration.description} </p>
                              <p>{vehicle.cvt_inspections_segment.description}</p>
                              <p>{vehicle.cvt_inspections_type.description} </p>
                              <p>{vehicle.application?.description} </p>
                          </div>
                          <div className="content-modal-button">
                            <Link to={{pathname:`/inspections_vehicle/${vehicle.id}?license_plate=${vehicle.license_plate}&fleetId=${id}`}}>
                              <button className="btn-action border-100 button-init small-icon">
                                  <span>Posiciones</span>
                                  <img src="/img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                              </button>
                            </Link>
                            <Link to={{pathname:`/update_inspections_vehicle/${id}?vehicleId=${vehicle.id}&license_plate=${vehicle.license_plate}&fleetId=${id}`}}>
                              <button className="btn-action border-100 button-init small-icon">
                                  <span>Editar</span>
                                  <img src="/img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                              </button>
                            </Link>
                          </div>
                      </div>
                    </div>
                  )})
                    }
                    <div className="container-tips content-tip-add">
                    <Link className="width-100" to={`/create_inspections_vehicle/${id}`}>
                      <div className="add-new"><div className="add-container">+</div></div>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {Fleet};