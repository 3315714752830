import React, {useState} from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import {useAuth} from './authContext';
import {Helmet} from "react-helmet";

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
  let history = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/home" } };
  let login = async (event) => {
    event.preventDefault();
    await auth.signin({email, password, rememberMe: true});
    history(from, {replace: true});
  };

  return (
    <div>
      <Helmet>
          <title>TruckTire - Login</title>
      </Helmet>
      <div className="login-continer">
      <div className="login-form" id='login-form'>
      <form onSubmit={login}>
          <h1>La manera más rápida de encontrar su producto</h1>
          <div className="container-form">
              <div className="container-input">
                  <input type='email' autoComplete='off' onChange={e => setEmail(e.target.value)} required className="input-class text-center" name="email" placeholder="Correo electrónico" />
              </div>
              <div className="container-input">
                  <input type='password' autoComplete='off' onChange={e => setPassword(e.target.value)} required className="input-class text-center" name="password" placeholder="Contraseña" />
                  <div className="icon-password"><img src="./img/icons/icon_password.svg" className="img-responsive" alt="Cart"/></div>
              </div>
              <div className="forgot-container"><a href="https://contitools.com/forgot-password" className="button-forgot">¿Olvidaste tu constraseña?</a></div>
          </div>
          <div className="container-form">
              <div className="container-input">
                  <button type="submit" className="btn-action button-init">
                      <span>Ingresar</span>
                  </button>
              </div>
              <div className="container-input">
                  <button onClick={() => window.open('https://contitools.com/create')} className="btn-action button-init" id="button-register">
                      <span>Registrarme</span>
                  </button>
              </div>
          </div>
          </form>
      </div>
  </div>
  <div className="tire-home"><img src="./img/tire_home.jpg"  alt=""/></div>
</div>
  );
}
export {Login};