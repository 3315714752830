import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {PrivateRoute} from './Routes/privateRoute';
import {ProvideAuth} from './Auth/provideAuth';
import {Login} from './Auth/Login';
import {Header} from './Header/Header';
import {Home} from './Home/Home';
import {TireSelector} from './TireSelector/TireSelector';
import {Products} from './Product/Products';
import {Product} from './Product/Product';
import {Treads} from './Tread/Treads';
import {Tread} from './Tread/Tread';
import {Quoter} from './Quoter/Quoter';
import {News} from './News/News';
import {Tips} from './Tips/Tips';
import {Support} from './Support/Support';
import {Fleets} from './Fleet/Fleets';
import {Fleet} from './Fleet/Fleet';
import {InspectionsVehicleForm} from './InspectionsVehicle/InspectionsVehicleForm';
import {InspectionsPositionForm} from './InspectionsPosition/InspectionsPositionForm';
import {InspectionsReviewForm} from './InspectionsReview/InspectionsReviewForm';
import {InspectionsVehicle} from './InspectionsVehicle/InspectionsVehicle';
import {InspectionsPosition} from './InspectionsPosition/InspectionsPosition';
import {FleetForm} from './Fleet/FleetForm';
import {SupportDetail} from './Support/SupportDetail';
import {getLocalStorage} from './LocalStorage/localStorageProvider';
import LoadingBar  from "./LoadingBar/LoadingBar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { DigitalSolutions } from "./DigitalSolutions/digitalSolutions";

const App = () => {
  const user = getLocalStorage('user');
  if(user) {
    ReactGA.initialize('G-TBZR11YJ66', { gaOptions: {userId: user.data.id, email: user.data.email}});
    ReactGA.send('pageview');
  }else{
      ReactGA.initialize('G-TBZR11YJ66', {});
      ReactGA.send('pageview');
  }
  return (
    <ProvideAuth>
      <Router>
      <Header/>
      <ToastContainer position="top-center" />
        <LoadingBar />
        <Routes>
            <Route exact path="/">
              {user ? <Route path="/" element={<Navigate replace to="/home" />} /> :  <Route path="/" element={<Navigate replace to="/login" />} />}
            </Route>
            <Route path="/login" element={ <Login />} />
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/home' element={<Home/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/' element={<Home/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/tireselector' element={<TireSelector/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/products' element={<Products/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/product/:id' element={<Product/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/treads' element={<Treads/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/tread/:id' element={<Tread/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/quoter' element={<Quoter/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/news' element={<News/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/tips' element={<Tips/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/support' element={<Support/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/supportdetail/:id' element={<SupportDetail/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/fleets' element={<Fleets/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/fleet/:id' element={<Fleet/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/createfleet' element={<FleetForm/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/inspections_vehicle/:id' element={<InspectionsVehicle/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/inspections_position/:id' element={<InspectionsPosition/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/create_inspections_vehicle/:id' element={<InspectionsVehicleForm/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/update_inspections_vehicle/:id' element={<InspectionsVehicleForm/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/create_inspections_position/:id' element={<InspectionsPositionForm/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/create_inspections_review/:id' element={<InspectionsReviewForm/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/digital_solutions' element={<DigitalSolutions/>}/>
            </Route>
          </Routes>

      </Router>
    </ProvideAuth>
  );
}

export default App;