import React, {useEffect, useState} from "react";
const Search =  ({products, originalProducts, filterProducts}) => {
  const findProducts = (searchValue) => {
    if(searchValue === '') {
      return filterProducts(originalProducts);
    }
    const productsFiltered = products.filter((product) => {
      return product.description?.toUpperCase().includes(searchValue.toUpperCase()) || 
      product.brand?.description?.toUpperCase().includes(searchValue.toUpperCase()) ||
      product.cvt_inspections_vehicle_brand?.description?.toUpperCase().includes(searchValue.toUpperCase()) ||
      product.cvt_inspections_type?.description?.toUpperCase().includes(searchValue.toUpperCase()) ||
      product.license_plate?.toUpperCase().includes(searchValue.toUpperCase())
    });

    filterProducts(productsFiltered);
  }
 return (<input placeholder="Buscar" onChange={(event) => findProducts(event.target.value)} className="input-class"/>)
}
export {Search};