import model from '../core/model';

const createReviews = async (params = {cvt_inspections_vehicle_id: null, cvt_inspections_fleet_id: null}) => {
  
  const reviewExcel = await model.post('/cvt_inspections/reviews/excel', params, {responseType: 'blob'});
  const blob = new Blob([reviewExcel.data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `review-${+new Date()}.xlsx`;
  link.click();
  return reviewExcel;
};
const createInspectionsReviews = async (body) => {
  return await model.post('/cvt_inspections/reviews', body);
}
const getInspectionsReviews = async (params = {cvt_inspections_position_id: null}) => {
  const reviews = await model.get('/cvt_inspections/reviews', {params});
  return reviews.data;
}
export {createReviews, getInspectionsReviews, createInspectionsReviews};