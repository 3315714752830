import model from '../core/model';
const getInspectionsVehicles = async (params = {cvt_inspections_fleet_id: null}) => {
  
  const vehicles = await model.get('/cvt_inspections/vehicles', {params});
  return vehicles.data;
};
const createInspectionsVehicles = async (body) => {
  return await model.post('/cvt_inspections/vehicles', body);
}
const updateInspectionsVehicles = async (body) => {
  return await model.put('/cvt_inspections/vehicles', body);
}
export {getInspectionsVehicles, createInspectionsVehicles, updateInspectionsVehicles}