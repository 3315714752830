import React, {useEffect, useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {getContents} from "../Content/apiContent";
import {baseUrlImages} from '../config';
import {Helmet} from 'react-helmet';

const SupportDetail = () => {
    let query = useLocation();
    query = query.pathname.split('/');
    const id  = query[2];
    const history = useNavigate();
    const [contents, setProducts] = useState([]);
    let content = {contents: []};
    if(contents.length) {
      content = contents[0];
    }
    const fetchContents = async () => {
      const fetchedProducts = await getContents({id});
      setProducts(fetchedProducts)
    }
    useEffect(() => {
        fetchContents();
  }, []);
  return (
    <div className="container-page">
                    <Helmet>
          <title>TruckTire - {content.title}</title>
      </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <span>{content.title}</span>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        {content.contents.map((contentIn) => {
        return(<div className="content-modal-black">
            <div className="img-tip">
                <img src={`${baseUrlImages}/${contentIn.picture}`} alt="" className="img-responsive" />
            </div>
            <p>{contentIn.description.replace(/(<([^>]+)>)/gi, "")}</p>
            <div className="content-modal-button">
                <a href={`${baseUrlImages}/${contentIn.pdf}`}>
                    <button className="btn-action border-100 button-init">
                        <span>Descargar PDF</span>
                        <img src="/img/icons/icon_download.svg" className="img-responsive" alt="Cart"/>
                    </button>
                </a>
            </div>
        </div>)
        })}   
    </div>
  </div>
  );
}
export {SupportDetail};