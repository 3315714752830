import React, {useEffect, useState} from "react";
import {getTypes} from "../Type/apiTypes";
import {getApplications} from "../Application/apiApplications";
import {getVehicles} from "../Vehicle/apiVehicles";
import {getDatabooks} from "../Databook/apiDatabooks";
import { useNavigate } from "react-router-dom";
import { ProductContainer } from "../Product/ProductContainer";
import  * as R from 'ramda';
import ReactTooltip from 'react-tooltip';
import {useAuth} from '../Auth/authContext';
import { toast } from 'react-toastify';
import {Helmet} from 'react-helmet';
const TireSelector = () => {
    const auth = useAuth();
    const history = useNavigate();
    const handleClick = (route = '/home') => {
      history(route)
    }
    const [types, setTypes] = useState([]);
    const [applications, setApplications] = useState([]);
    const [application, setApplication] = useState(null);
    const [positions, setPosition] = useState([]);
    const [mesure, setMesure] = useState([]);
    const [databooks, setDatabooks] = useState([]);
    const [filteredDatabooks, setFilteredDatabooks] = useState({});
    const [type, setType] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [vehicle, setVehicle] = useState(null);
    const addBorder = (id) => {
        const applications = document.querySelectorAll('.applications-class');
        applications.forEach(application => application.style.border = null)
        if(document.getElementById(id).style.border) {
            document.getElementById(id).style.border=null;
        }else{

            document.getElementById(id).style.border="2px solid orange";
            document.getElementById(id).style['border-radius']="40px";
        }
    }

    const vehicleSelected = (value) => {
        const vehicle = vehicles.find((vehicle) => vehicle.id.toString() === value);
        const selector = document.getElementById('vehicle_selector');
        selector.classList.add("hidden");
        setVehicle(vehicle);
        setFilteredDatabooks({});
    }
    const positionVehicleCoords = (event) => {
        const x = event.clientX - 50;
        console.log(x);
        if(type === '1' && vehicle.id !== 22) {
            if(x >= 230) {
                setPosition(['A', 'S']);
            }
            if(x > 90 && x < 230) {
                setPosition(['A', 'D']);
            }
            if(x <= 90) {
                setPosition(['A', 'T', 'S']);
            }
        }
        if(type === '1' && vehicle.id === 22) {
            if(x >= 230) {
                setPosition(['A', 'S']);
            }
            if(x < 230) {
                setPosition(['A', 'D']);
            }
        }
        if(type === '2') {
            if(x >= 230) {
                setPosition(['A', 'S']);
            }
            if(x < 230) {
                setPosition(['A', 'D']);
            }

            if(vehicle.filter === 'Arrastre') {
                setPosition(['A', 'T','S']);
            }
        }
        if(type === '3' && vehicle.id === 40) {
            if(x >= 230) {
                setPosition(['A', 'S']);
            }
            if(x > 130 && x < 230) {
                setPosition(['A', 'T', 'S']);
            }
            if(x <=130) {
                setPosition(['A', 'D']);
            }
        }
        if(type === '3' && vehicle.id !== 40) {
            if(x >= 230) {
                setPosition(['A', 'S']);
            }

            if(x <=230) {
                setPosition(['A', 'D']);
            }
        }
        const selector = document.getElementById('vehicle_selector');
        selector.style.position = 'relative';
        selector.style.left = `${x}px`;
        selector.style.top = `-50px`;
        selector.classList.remove("hidden");
        setFilteredDatabooks({});
    }
    const fetchTypes = async () => {
      const fetchedTypes = await getTypes();
      setTypes(fetchedTypes)
    }
    const fetchApplications = async () => {
        let fetchedApplications = await getApplications();
        if(auth?.user?.data?.country_id === 1) {
            fetchedApplications = fetchedApplications.filter(application => application.id !== 5);
        }
        setApplications(fetchedApplications);
      }
    const fetchDatabooks = async () => {
      const fetchedDatabooks = await getDatabooks();
      setDatabooks(fetchedDatabooks)
    }
    const filterDatabook = () => {
        if(!mesure || !application || !positions.length) {
            toast.error('Todos los filtros son obligatorios, no te olvides de seleccionar una aplicación y un eje.');
        }
        let filterDatabooks = databooks;

        filterDatabooks = filterDatabooks.filter((databook) => {
            return databook.cvt_databook.mesure === mesure;
        });
        filterDatabooks = filterDatabooks.filter((databook) => {
            return databook.cvt_databook.product.applications.find(applicationProduct => applicationProduct.id === application);
        });
        filterDatabooks = filterDatabooks.filter((databook) => {
            return positions.find(position => position === databook.cvt_databook.position);
        });
        filterDatabooks = filterDatabooks.filter((databook) => databook?.cvt_databook?.recommended_vehicles?.includes(vehicle?.filter));
        filterDatabooks = filterDatabooks.map((databook) => {
            const newDatabook = {...databook};
            newDatabook.product_id = newDatabook.cvt_databook.product_id;
            return newDatabook;
        });
        const groupByProduct = R.groupBy(R.prop('product_id'));
        const groupedDatabooks = groupByProduct(filterDatabooks);

        setFilteredDatabooks(groupedDatabooks);
    }
    const fetchVehicles = async () => {
      const fetchedVehicles = await getVehicles();
      setVehicles(fetchedVehicles)
    }
    useEffect(() => {
        fetchTypes();
        fetchVehicles();
        fetchApplications();
        fetchDatabooks();
  }, []);
  return (
    <div>
                                  <Helmet>
          <title>TruckTire - TireSelector</title>
      </Helmet>
  <div className="container-page ">
    <div className="header-form">
        <div className="title-icon">
            <img src="img/icons/icon_tireselector_white.svg" className="img-responsive" alt="Cart"/>
            <span>TireSelector</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
                <img src="./img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12 col-sm-4 padding-left filter-content">
                <p>Define los parámetros de búsqueda para la llanta que se
                    adapte mejor a tu vehículo y tus exigencias.</p>
                <div className="container-form">
                    <div className="container-input">
                        <select onChange={(event) => setType(event.target.value)} className="input-class text-center">
                            <option>*Segmento</option>
                            {types.map((type) => {
                                return(<option key={type.id} value={type.id}>{type.description}</option>)
                            })}
                        </select>
                        <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                    </div>
                    <div className="container-input">
                        <select onChange={(event) => vehicleSelected(event.target.value)} className="input-class text-center">
                            <option>*Configuración del vehículo</option>
                            {vehicles.filter((vehicle) => vehicle.type_id.toString() === type).map((vehicle) => {
                                return(<option key={vehicle.id} value={vehicle.id}>{vehicle.description}</option>)
                            })}
                        </select>
                        <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                    </div>
                    <div className="container-input">
                        <select onChange={(event) => {setMesure(event.target.value);setFilteredDatabooks({});}} className="input-class text-center">
                            <option>*Medida</option>
                            {databooks
                                .filter((databook) => databook?.cvt_databook?.recommended_vehicles?.includes(vehicle?.filter))
                                .map((databook) => databook?.cvt_databook?.mesure)
                                .filter((value, index, self) => self.indexOf(value) === index)
                                .map((databook, i) => {
                                return(<option key={i} value={databook}>{databook}</option>)
                            })}
                        </select>
                        <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                    </div>
                </div>    
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Aplicaci&oacute;n</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <p>Selecciona la aplicación de la llanta.</p>
                    <div className="aplications">
                        {applications.map((application, k) => {
                            return(
                                <div key={k} className="img-aplications">
                                    <img id={`app-${application.id}`} onClick={() => {addBorder(`app-${application.id}`); setApplication(application.id);setFilteredDatabooks({});}} style={{cursor: 'pointer'}} src={`img/aplications/${application.picture}`} data-tip={application.description} alt={application.description} className="applications-class img-responsive" />
                                    <ReactTooltip />
                                </div>
                            )
                        })}

                    </div>
                </div>
                <div className="content-information bottom-information">
                    <div className="title-h2">
                        <h2>Ejes</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <p>Selecciona el eje (direccional, tracción o arrastre).</p>
                    <div className="img-truck">
                        {vehicle?.picture &&
                        <img id="vehicle_image" onClick={(event) => positionVehicleCoords(event)} src={`img/vehicles/${vehicle?.picture}`} alt="" style={{height: '150px', width: '100%'}}/>
                        }
                        <div id="vehicle_selector" className="selector-truck hidden"><img src="img/icons/icon_selector.svg" alt="" className="img-responsive" /></div>
                    </div>
                    <button onClick={() => filterDatabook() } className="btn-action border-100 button-init button-close-session">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
            <div className="col-12 col-sm-8 tires-content">
                <div className="title-h2">
                    <h2>Productos</h2>
                    <div className="line">&nbsp;</div>
                </div>
                <div className="container-tires">
                { Object.keys(filteredDatabooks).map((productId) => {
                    const data = filteredDatabooks[productId];
                    const product = filteredDatabooks[productId][0]?.cvt_databook?.product;
                    
                    return(<ProductContainer key={productId} product={product} databooks={data} />)
                })}
                </div>
            </div>
        </div>
    </div>
  </div>
    </div>
  );
}
export {TireSelector};