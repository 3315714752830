import React, {useEffect, useState} from "react";
import { getCart, removeCart } from "./quoterProvider";
import {baseUrlImages} from '../config';
import { getParams } from "../Params/apiParams";
import { createQuotation } from "./apiQuoter";
import {round} from '../helpers/helpers';
import {user} from '../Auth/provideUser';
import { ProductContainer } from "../Product/ProductContainer";
import {getDatabooks} from "../Databook/apiDatabooks";
import * as R from 'ramda';
import {Helmet} from 'react-helmet';
import { toast } from 'react-toastify';

const Quoter = () => {
  const [cart, setCart] = useState(getCart());
  const [client, setClient] = useState({name: null, phone: null, address: null, identity: null, email: null, distributor: user?.data?.distributor?.description, comment: null});
  const [params, setParams] = useState([]);
  const [totals, setTotals] = useState({tax: 0, subTotal: 0, total: 0});
  const [databooks, setDatabooks] = useState([]);
  const [filteredDatabooks, setFilteredDatabooks] = useState({});
  const getSubtotal = () => {
      let values = cart.map(mesure => mesure.subTotal);
      values = values.filter(value => value);
        let subTotalS = values.reduce((a,b) => {
        return a + b;
        }, 0);
        subTotalS = round(subTotalS, 2);
        const taxValue = params.find((param) => param.type === 'TAX').value / 100;
        const tax = round(subTotalS *  taxValue, 2);
        const total = round(subTotalS + tax, 2);

    setTotals({tax, subTotal: subTotalS, total});
  }
  const fetchParams = async () => {
    const params = await getParams();
    setParams(params);
  }
  const search = (searchValue) => {
    if(!searchValue) {
        setFilteredDatabooks({});
        return;
    }
    let filterDatabooks = databooks;

    filterDatabooks = filterDatabooks.filter((databook) => {
        return databook.product.description.toUpperCase().includes(searchValue.toUpperCase()) || databook.cvt_databook.mesure.toUpperCase().includes(searchValue.toUpperCase())
    });

    filterDatabooks = filterDatabooks.map((databook) => {
        const newDatabook = {...databook};
        newDatabook.product_id = newDatabook.cvt_databook.product_id;
        return newDatabook;
    });
    const groupByProduct = R.groupBy(R.prop('product_id'));
    const groupedDatabooks = groupByProduct(filterDatabooks);

    setFilteredDatabooks(groupedDatabooks);
}
  const fetchDatabooks = async () => {
    const fetchedDatabooks = await getDatabooks();
    setDatabooks(fetchedDatabooks)
  }
  const addRemoveService = (paramId) => {
    let newCart = [...cart];
    const param = params.find((param) => param.id.toString() === paramId.toString());
    param.price = param.value;
    param.productDescription = param.description;
    param.productPicture = param.picture;
    const exists = !! cart.find((cartItem) => cartItem.id.toString() === paramId.toString() && cartItem.type === 'SERVICE');
    if(exists) {
        newCart = cart.filter((cartItem) => cartItem.id.toString() !== paramId.toString() || !cartItem.type);
    }
    if(!exists) {
        newCart.push(param);
    }

    setCart(newCart);
    getSubtotal();
  }
  const calculateData = (mesureId, value, name) => {
  const newCart = [...cart];
  const mesure = newCart.find((mesureCart) => mesureCart.id === mesureId);
  mesure[name] = value;
  const sub = mesure.quantity * mesure.price;
  const discount = mesure.discount || 0;
  const subTotal = sub - discount;
  mesure.subTotal = round(subTotal, 2);
  setCart(newCart);
  getSubtotal();
  }
  const setClientData = (field, value) => {
    const newClient = {...client};
    newClient[field] = value;
    setClient(newClient);
  }
  const downloadPdf = async () => {
    const products = cart.filter((item) => item.product);
    const eco = cart.find((item) => item.productDescription.toLowerCase() === 'ecovalor');
    console.log("🚀 ~ file: Quoter.js:98 ~ downloadPdf ~ eco:", eco)
    if(products.length > 0 && !eco) {
        return toast.error('Si seleccionas un producto es necesario el servicio Ecovalor');
    }
    await createQuotation(client, cart, totals)
  }
  useEffect(() => {
    fetchParams();
    fetchDatabooks();
  }, []);
  return (
    <div className="container-page">
      <Helmet>
          <title>TruckTire - Cotizador</title>
      </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_cotizador_white.svg" className="img-responsive" alt="Cart"/>
            <span>Cotizador</span>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12 txt-2columns">
                <p>Llena los campos que requieras y realiza la búsqueda por nombre o medida de la llanta.</p>
            </div>
            <div className="col-12 col-sm-4 padding-left filter-content">
                
                <div className="container-form">
                    <div className="title-h2">
                        <h2>Datos</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="field-modal container-input">
                            <input type="text" value={client.name || ''} onChange={(event) => setClientData('name', event.target.value)} className="input-class text-center w-100" placeholder="Nombre"/>
                    </div>
                    <div className="field-modal container-input">
                            <input type="text" value={client.phone || ''} onChange={(event) => setClientData('phone', event.target.value)} className="input-class text-center w-100" placeholder="Teléfono"/>
                    </div>
                    <div className="field-modal container-input">
                            <input type="text" value={client.address || ''} onChange={(event) => setClientData('address', event.target.value)} className="input-class text-center w-100" placeholder="Dirección"/>
                    </div>
                    <div className="field-modal container-input">
                            <input type="text" value={client.identity || ''} onChange={(event) => setClientData('identity', event.target.value)} className="input-class text-center w-100" placeholder="Cédula/Identidad/RUC"/>
                    </div>
                    <div className="field-modal container-input">
                        <input type="email" value={client.email || ''} onChange={(event) => setClientData('email', event.target.value)} className="input-class text-center w-100" placeholder="Email"/>
                    </div>
                    <div className="field-modal container-input">
                        <input type="text" value={client.distributor || ''} onChange={(event) => setClientData('distributor', event.target.value)} defaultValue={user?.data?.distributor?.description}className="input-class text-center w-100" placeholder="Distribuidor"/>
                    </div>
                    <div className="container-input">
                        <input type='text' value={client.fleet || ''}  onChange={(event) => setClientData('fleet', event.target.value)}  className="input-class text-center" name="flota" placeholder="Nombre del cliente/flota" />
                    </div>
                    <div className="container-input">
                        <input type='text' value={client.comment || ''}  onChange={(event) => setClientData('comment', event.target.value)}  className="input-class text-center" name="Comentarios" placeholder="Comentarios" />
                    </div>
                </div> 
                <div className="container-form">
                    <div className="title-h2">
                        <h2>Llantas</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="container-input">
                        <input type='text' onChange={(event) => search(event.target.value)}  className="input-class text-center" name="email" placeholder="Escriba aquí el nombre o medida" />
                    </div>
                    <div className="container-tires on-quoter">
                        { Object.keys(filteredDatabooks).map((productId) => {
                            const data = filteredDatabooks[productId];
                            const product = filteredDatabooks[productId][0]?.cvt_databook?.product;
                            
                            return(<ProductContainer product={product} databooks={data} refreshCart={setCart} />)
                        })}
                    </div>
                </div>  
                <div className="field-modal">
                      <h3>Agregar servicios</h3>
                      <div className="container-services">
                          {params.filter((param) => param.type === 'SERVICE' && param.app === 'CVT').map((param, k) => {
                              return(
                          <div key={k} className="container-checkbox">
                              <span>{param.description}</span>
                              <label className="container-c">
                                  <input onClick={(event) => addRemoveService(event.target.value)} type="checkbox" value={param.id} className="input-checkbox" />
                                  <span className="checkmark"></span>
                              </label>
                          </div>)
                          })}
                      </div> 
                  </div>
                <div className="container-form button-cotizador">
                    <div className="content-modal-button justify-content-between">
                        <button onClick={() => setClient({name: null})} className="btn-action gray border-100 button-init small-icon">
                            <span>Borrar cotización</span>
                            <img src="/img/icons/icon_close.svg" className="img-responsive" alt="Cart"/>
                        </button>
                        <button onClick={() => downloadPdf()} className="btn-action border-100 button-init small-icon">
                            <span>Descargar cotización</span>
                            <img src="/img/icons/icon_download.svg" className="img-responsive" alt="Cart"/>
                        </button>
                    </div>
                </div>  
            </div>
            <div className="col-12 col-sm-8 tires-content">
                <div className="title-h2 margin-top">
                    <h2>Productos</h2>
                    <div className="line">&nbsp;</div>
                </div>
                <div className="container-tires content-cotizar">
                    <div className="content-list-tires">
                        {cart.map((mesure) => {
                            return(<div key={mesure.id} className="container-tire-cotizador">
                                <div  className="img-tire img-tire-cotizador">
                                    <img src={`${baseUrlImages}/${mesure.productPicture}`} alt="" className="img-responsive" />
                                </div>
                                <div className="content-tire-cotizador">
                                    <div className='info-app'>
                                        <div className="title-information padding-cotizador">
                                            <div className="circle-color circle-big red">&nbsp;</div>
                                            <span>{mesure.productDescription}</span>
                                            -
                                            <span>{mesure.mesure}</span>
                                        </div>
                                        {mesure.type === 'SERVICE' && (mesure.description.toLowerCase().includes('otros')) && <div className="text-cotizador">
                                            <span className="light">Descripción:</span>
                                            <input type='text' onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)}  className="input-class text-center" name="productDescription" placeholder="Descripción del servicio" />
                                        </div>}
                                        <div className="text-cotizador">
                                            <span className="light">Valor unitario:</span>
                                            <input type='number'defaultValue={mesure.price || 0} onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)}  className="input-class text-center" name="price" placeholder="Valor unitario" />
                                        </div>
                                        <div className="text-cotizador">
                                            <span className="light">Cantidad:</span>
                                            <input type='number' onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)}  className="input-class text-center" name="quantity" placeholder="Cantidad" />
                                        </div>
                                        <div className="text-cotizador">
                                            <span className="light">Descuento:</span>
                                            <input type='number' onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)}  className="input-class text-center" name="discount" placeholder="Descuento" />
                                        </div>
                                        <div className="text-cotizador">
                                            <span>Valor subtotal:</span>
                                            <input readOnly type='number' value={mesure.subTotal} className="input-class text-center" name="subtotal" placeholder="Subtotal" />
                                        </div>
                                    </div>
                                </div>
                                <a onClick={() => {removeCart(mesure); setCart(getCart())}}href="#" className="cotizador-close"><img src="/img/icons/icon_close_red.svg" className="img-responsive" alt="Cart"/></a>
                            </div>)
                        })}
                    </div>
                    <div className="content-prices">
                        <div className="content-tire-cotizador black">
                            <div className='info-app'>
                                <div className="text-cotizador">
                                    <span className="light">Subtotal:</span>
                                    <span>{totals.subTotal}</span>
                                </div>
                                <div className="text-cotizador">
                                    <span className="light">{params.find((param) => param.type === 'TAX')?.description} {params.find((param) => param.type === 'TAX')?.value}%:</span>
                                    <span>{totals.tax}</span>
                                </div>
                                <div className="text-cotizador">
                                    <span>Total:</span>
                                    <span>${totals.total}</span>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {Quoter};