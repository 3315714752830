import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import {getTypes} from "../Type/apiTypes";
import {getApplications} from "../Application/apiApplications";
import {useAuth} from '../Auth/authContext';
import {Helmet} from 'react-helmet';
import { ProductContainer } from "../Product/ProductContainer";
import {getDatabooks} from "../Databook/apiDatabooks";
import * as R from 'ramda';
import { BarChart, Bar, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";


const DigitalSolutions = () => {
    const decimalsAllowed = '.1';
    const doc = new jsPDF({
        format: 'a4',
        unit: 'px',
    });
    const prefixedValues = {
        percentageVerifiedVehicles: 25,
        percentageFleetLowPressure: 20,
        percentageFuelLowPressure: 1,
    }
    const auth = useAuth();
    const currencies = ['USD', 'COP'];
    const currencySymbol = {
        USD: '$',
        COP: 'COP '
    };
    const history = useNavigate();
    const handleClick = (route = '/home') => {
        history(route);
    }
    const reportTitle = useRef(null);
    const report1 = useRef(null);
    const report2 = useRef(null);
    const [form, setForm] = useState({yearsOfProjection: 5});
    const [chart1, setChart1] = useState([]);
    const [chart2, setChart2] = useState([]);
    const [chart2_1, setChart2_1] = useState([]);
    const [chart3, setChart3] = useState([]);
    const [chart4, setChart4] = useState([]);
    const [databooks, setDatabooks] = useState([]);
    const [filteredDatabooks, setFilteredDatabooks] = useState({});
    const search = (searchValue) => {
        if(!searchValue) {
            setFilteredDatabooks({});
            return;
        }
        let filterDatabooks = databooks;
    
        filterDatabooks = filterDatabooks.filter((databook) => {
            return databook.product.description.toUpperCase().includes(searchValue.toUpperCase()) || databook.cvt_databook.mesure.toUpperCase().includes(searchValue.toUpperCase())
        });
    
        filterDatabooks = filterDatabooks.map((databook) => {
            const newDatabook = {...databook};
            newDatabook.product_id = newDatabook.cvt_databook.product_id;
            return newDatabook;
        });
        const groupByProduct = R.groupBy(R.prop('product_id'));
        const groupedDatabooks = groupByProduct(filterDatabooks);
    
        setFilteredDatabooks(groupedDatabooks);
    }
      const fetchDatabooks = async () => {
        const fetchedDatabooks = await getDatabooks();
        setDatabooks(fetchedDatabooks)
      }
    const [output, setOutput] = useState({
        daysToFixTires1: 0,
        staffTimeConsuption1: 0,
        annualMaintenance1: 0,
        anualPNP1: 0,
        lostValueTireLife1: 0,
        lossProfit1: 0,
        fuelConsumption1: 0,
        daysToFixTires2: 0,
        staffTimeConsuption2: 0,
        annualMaintenance2: 0,
        anualPNP2: 0,
        lostValueTireLife2: 0,
        lossProfit2: 0,
        fuelConsumption2: 0,
        daysToFixTires3: 0,
        staffTimeConsuption3: 0,
        annualMaintenance3: 0,
        anualPNP3: 0,
        lostValueTireLife3: 0,
        lossProfit3: 0,
        fuelConsumption3: 0,
        totalCost1: 0,
        totalCost2: 0,
        totalCost3: 0,
        totalInvestment: 0,
        projectionYears: {
            investmentMonthlyPayments: [],
            saving: [],
            payBack: 0

        },
        projectionMonthly: {
            totalTireChange: 0,
            tiresChangedPerYear: [],
            months: [],
            completeAnnualTireChange: 0,
            completeMonthlyTireChange: 0
        }
    });
    const [types, setTypes] = useState([]);
    const [applications, setApplications] = useState([]);
    const fetchTypes = async () => {
        const fetchedTypes = await getTypes();
        setTypes(fetchedTypes)
    }
    const fetchApplications = async () => {
        let fetchedApplications = await getApplications();
        if(auth?.user?.data?.country_id === 1) {
            fetchedApplications = fetchedApplications.filter(application => application.id !== 5);
        }
        setApplications(fetchedApplications);
        }
    const setData = (position, value) => {
        let newForm = {...form}
        newForm[position] = value;
        newForm.totalTires = newForm.totalVehicles * newForm.averageTiresPerVehicle;
        newForm.numberOfSensors = newForm.totalTires;
        setForm(newForm);
    }
    const setProduct = (product, value) => {
        console.log("🚀 ~ file: digitalSolutions.js:120 ~ setProduct ~ value:", value)
        console.log("🚀 ~ file: digitalSolutions.js:120 ~ setProduct ~ product:", product)
        let newForm = {...form}
        newForm['product'] = `${product.code}-${product.mesure}-${product.ic}${product.lr} ${product.productDescription}`;
        newForm['productValue'] = value || '';
        newForm.totalTires = newForm.totalVehicles * newForm.averageTiresPerVehicle;
        newForm.numberOfSensors = newForm.totalTires;
        setForm(newForm);
    }
    const downloadPdf = async () => {
        const canvas = await html2canvas(reportTitle.current);
        const canvas1 = await html2canvas(report1.current);
        const canvas2 = await html2canvas(report2.current);
        doc.addImage('img/logo_contitool.png','png', 25, 20, 150, 25);
        doc.addImage('img/digital.png','png', 320, 5, 100, 45);

        doc.addImage(canvas, 'png', 32, 55, 385, 7);
        doc.addImage(canvas1, 'png', 25, 65, 400, 550);
        doc.addPage();
        doc.addImage(canvas2, 'png', 30, 10, 400, 500);
        doc.save('digital_solutions_report');

    }
    const generateReport = (event) => {
        //=SI.ERROR(Input!H15*Input!H18*(Input!H19/60)/(Input!H22*Input!H20);"Invalido")
        const daysToFixTires1 = form.totalTires * form.inspectionsPerVehiclePerMonth * (form.averageTimePerVehiclePerTire / 60) / (form.staffNumber * form.dailyWorkingHours);
        const daysToFixTires2 = prefixedValues.percentageVerifiedVehicles / 100 * daysToFixTires1;
        const daysToFixTires3 = daysToFixTires1 - daysToFixTires2;
        //=SI.ERROR(C11/Input!H21;0)
        const staffTimeConsuption1 = (daysToFixTires1 / form.daysWorked) * 100 || 0;
        const staffTimeConsuption2 = (daysToFixTires2 / form.daysWorked) * 100 || 0;
        const staffTimeConsuption3 = staffTimeConsuption1 - staffTimeConsuption2 || 0;
        //=SI.ERROR(C11*Input!H23*Input!H22/Input!H21*12;0)
        const annualMaintenance1 = daysToFixTires1 * form.staffSalary * form.staffNumber / form.daysWorked * 12 || 0;
        const annualMaintenance2 = daysToFixTires2 * form.staffSalary * form.staffNumber / form.daysWorked * 12 || 0;
        const annualMaintenance3 = annualMaintenance1 - annualMaintenance2 || 0;
        //=SI.ERROR(Input!Q12*Input!Q13*12;0)
        const anualPNP1 = form.pnp * form.pnpSpent * 12 || 0;
        //=C14*Input!Q14
        const anualPNP2 = anualPNP1 * (form.deletedPercentageCC / 100) || 0;
        const anualPNP3 = anualPNP1 -  anualPNP2 || 0;
        //=SI.ERROR(Input!H15*Input!H25*Input!H26*Input!H27;0)
        const lostValueTireLife1 = form.totalTires * (form.lowPressurePercentage / 100) * (form.increasedTireWear / 100) * form.averageReplacementCost || 0;
        const lostValueTireLife3 = lostValueTireLife1 || 0;
        //=Input!Q19/(Input!H14/12)*Input!Q21*Input!Q20*12*Input!Q12
        const lossProfit1 = form.monthlyBillingPerVehicle / (form.averageKmPerVehicle / 12) * form.lostHours * form.averageFleetSpeed * 12 * form.pnp || 0;
        //=Input!Q19/(Input!H14/12)*Input!Q21*Input!Q20*Input!Q12*Input!Q14*12
        const lossProfit2 = form.monthlyBillingPerVehicle / (form.averageKmPerVehicle / 12) * form.lostHours * form.averageFleetSpeed * form.pnp * (form.deletedPercentageCC / 100) * 12 || 0;
        const lossProfit3 = lossProfit1 - lossProfit2 || 0;
        //=SI.ERROR(Input!Q17*Input!H14*Input!H11/Input!Q16;0)
        const fuelConsumption1 = form.dieselPrice * form.averageKmPerVehicle * form.totalVehicles / form.fleetConsumption || 0;
        const fuelConsumption3 = fuelConsumption1 * (prefixedValues.percentageFuelLowPressure / 100) * (prefixedValues.percentageFleetLowPressure / 100) || 0;
        const fuelConsumption2 = fuelConsumption1 - fuelConsumption3 || 0;
        const totalCost1 =  annualMaintenance1 + anualPNP1 + lostValueTireLife1 + lossProfit1 + fuelConsumption1;
        const totalCost2 =  annualMaintenance2 + anualPNP2  + lossProfit2 + fuelConsumption2;
        const totalSaving =  annualMaintenance3 + anualPNP3 + lostValueTireLife3 + lossProfit3 + fuelConsumption3;
        //=(Input!Q23*Input!Q25*12)+(Input!Q26*Input!Q27)+Input!Q24
        const totalInvestment = (form.numberOfAntennas * form.monthlyFeePerYardReader * 12) + (form.numberOfSensors * form.sensorCost) + form.installationCostPerYardReaders
        const investmentMonthlyPayments = [totalInvestment];
        const saving = [0, totalSaving]
        for(let i = 1; i <= form.yearsOfProjection; i++ ) {
            investmentMonthlyPayments[i] = investmentMonthlyPayments[i-1]+(form.numberOfAntennas * form.monthlyFeePerYardReader * 12);
        }
        for(let i = 2; i <= form.yearsOfProjection; i++ ) {
            saving[i] = totalSaving * i;
        }
        const payBack = investmentMonthlyPayments[1] /saving[1];
        const tiresChangedPerYear = [0, form.monthlyReplacement];
        const months = [0, form.monthlyReplacement];
        for(let i = 2; i < form.yearsOfProjection * 12 + 1; i++) {
            tiresChangedPerYear[i] =  form.monthlyReplacement * i;
            months[i] = tiresChangedPerYear[i] <= form.totalTires ? tiresChangedPerYear[i] : form.totalTires;
        }

        //=Input!H15/Input!H13/12
        const completeAnnualTireChange = Math.floor(form.totalTires / form.monthlyReplacement / 12);
        //=REDONDEAR.MAS(C30/Input!H13;0)
        const completeMonthlyTireChange = Math.ceil(form.totalTires / form.monthlyReplacement);
        const newChart1 = investmentMonthlyPayments.map((data, index) => {
            return {
                name: `${index} años`,
                line1: data,
                line2: saving[index] 
            }
        })
        setChart1(newChart1);
        const newChart3 = months.map((data, index) => {
            return {
                name: `${index + 1} mes`,
                line2: data,
                line1: form.totalTires
            }
        })
        setChart3(newChart3);

        setChart2([
            {name: 'Mantenimiento Anual', pv: annualMaintenance1, color: '#FFA400'},
            {name: 'PNP Anual', pv: anualPNP1, color: '#C00A27'},
            {name: 'Pérdida de la vida útil de los neumáticos', pv: lostValueTireLife1, color: '#2E2C94'},
            {name: 'Lucro Cesante', pv: lossProfit1, color: '#2D2D2D'},
        ])
        setChart2_1([
            {name: 'Consumo de Combustible', pv: fuelConsumption1, color: '#D6D7D8'},
        ])
        setChart4([
            {name: 'Mantenimiento Anual', pv: annualMaintenance3, color: '#FFA400'},
            {name: 'PNP Anual', pv: anualPNP3, color: '#C00A27'},
            {name: 'Pérdida de la vida útil de los neumáticos', pv: lostValueTireLife3, color: '#2E2C94'},
            {name: 'Lucro Cesante', pv: lossProfit3, color: '#2D2D2D'},
            {name: 'Consumo de Combustible', pv: fuelConsumption3, color: '#D6D7D8'},
        ])
        const newOutput = {
            daysToFixTires1,
            staffTimeConsuption1,
            annualMaintenance1,
            anualPNP1,
            lostValueTireLife1,
            lossProfit1,
            fuelConsumption1,
            daysToFixTires2,
            staffTimeConsuption2,
            annualMaintenance2,
            anualPNP2,
            lostValueTireLife2: 0,
            lossProfit2,
            fuelConsumption2,
            daysToFixTires3,
            staffTimeConsuption3,
            annualMaintenance3,
            anualPNP3,
            lostValueTireLife3,
            lossProfit3,
            fuelConsumption3,
            totalCost1,
            totalCost2,
            totalSaving,
            totalInvestment,
            projectionYears: {
                investmentMonthlyPayments,
                saving,
                payBack

            },
            projectionMonthly: {
                totalTireChange: form.totalTires,
                tiresChangedPerYear,
                months,
                completeAnnualTireChange,
                completeMonthlyTireChange
            }
        }
        if(event) {
            event.preventDefault();
        }

        setOutput(newOutput);
    }
    useEffect(() => {
        fetchTypes();
        fetchApplications();
        fetchDatabooks();
    }, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Digital Solutions</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="./img/icons/icon_continews_white.svg" className="img-responsive" alt="Cart"/>
            <span>Digital Solutions</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
                <img src="./img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12 txt-2columns">
                <p>Llena los campos requeridos.</p>
            </div>
            <div className="col-12 col-sm-4 padding-left filter-content">
                <form onSubmit={generateReport}>
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Datos Generales</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                            <label>Proyección en años:</label>
                            <input type="number" step={decimalsAllowed} name="yearsOfProjection" className="input-class text-center w-100" placeholder="Proyección en años" value={form.yearsOfProjection} onChange={(event) => {setData(event.target.name, event.target.value); }} /> 
                        </div>
                        <div className="field-modal container-input">
                                <input required type="text" name="fleet" value={form.fleet} onChange={(event) => {setData(event.target.name, event.target.value); }}   className="input-class text-center w-100" placeholder="Flota"/>
                        </div>
                        <div className="field-modal container-input">
                            <select name="segment" value={form.segment} onChange={(event) => {setData(event.target.name, event.target.value); }}  className="input-class text-center">
                                <option>Segmento</option>
                                {types.map((type) => {
                                    return(<option key={type.id} value={type.id}>{type.description}</option>)
                                })}
                            </select>
                            <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                        </div>
                        <div className="field-modal container-input">
                            <select required name="application" value={form.application} onChange={(event) => {setData(event.target.name, event.target.value); }}  className="input-class text-center">
                                <option>Aplicación</option>
                                {applications.map((type) => {
                                    return(<option key={type.id} value={type.id}>{type.description}</option>)
                                })}
                            </select>
                            <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                        </div>
                        <div className="field-modal container-input">
                            <select required name="currency" value={form.currency} onChange={(event) => {setData(event.target.name, event.target.value); }}  className="input-class text-center">
                                <option>Moneda</option>
                                {currencies.map((currency) => {
                                    return(<option key={currency} value={currency}>{currency}</option>)
                                })}
                            </select>
                            <img src="img/icons/icon_arrow.svg" alt="" className="icon-select" />
                        </div>
                        
                    </div> 
                    <div className="container-form">
                    <div className="title-h2">
                        <h2>Llantas</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="container-input">
                        <input type='text' onChange={(event) => search(event.target.value)}  className="input-class text-center" placeholder="Escriba aquí el nombre o medida" />
                    </div>
                    <div className="container-input">
                        <input required type='text' value={form.product} className="input-class text-center" name="product" readOnly placeholder="Producto seleccionado" />
                    </div>
                    <div className="container-input">
                        <input required type="number" step={decimalsAllowed} value={form.productValue} className="input-class text-center" name="productValue" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Valor del producto" />
                    </div>
                        <div className="container-tires on-quoter">
                            { Object.keys(filteredDatabooks).map((productId) => {
                                const data = filteredDatabooks[productId];
                                const product = filteredDatabooks[productId][0]?.cvt_databook?.product;
                                
                                return(<ProductContainer product={product} databooks={data} simpleAdd={setProduct} />)
                            })}
                        </div>
                    </div>  
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Datos de la Flota</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="totalVehicles" value={form.totalVehicles} onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }}   className="input-class text-center w-100" placeholder="Cantidad total de vehículos"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="averageTiresPerVehicle" value={form.averageTiresPerVehicle} onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }}   className="input-class text-center w-100" placeholder="Promedio de neumáticos por vehículo "/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="monthlyReplacement" value={form.monthlyReplacement} onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }}   className="input-class text-center w-100" placeholder="Potencial de reposición por mes"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="averageKmPerVehicle" value={form.averageKmPerVehicle} onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }}   className="input-class text-center w-100" placeholder="Promedio de kilómetros rodados por camión por año"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="totalTires" value={form.totalTires} readOnly   className="input-class text-center w-100" placeholder="Total neumáticos en la flota"/>
                        </div>
                        
                    </div> 
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Costos de la Flota</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="inspectionsPerVehiclePerMonth" value={form.inspectionsPerVehiclePerMonth}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Cantidad de inspecciones por vehículo por mes"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="averageTimePerVehiclePerTire" value={form.averageTimePerVehiclePerTire}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Tiempo promedio en minutos para inspección por neumático"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="dailyWorkingHours" value={form.dailyWorkingHours}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Horas trabajadas en el día"/>
                        </div>
                        <div className="field-modal container-input">
                                <input required type="number" step={decimalsAllowed} name="daysWorked" value={form.daysWorked}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Días trabajados en el mes"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="staffNumber" value={form.staffNumber}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="N° de personal a cargo de neumáticos"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="staffSalary" value={form.staffSalary}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Salario del personal a cargo de neumáticos por mes"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="lowPressurePercentage" value={form.lowPressurePercentage}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Porcentaje de la flota que rueda con presión baja (Datos de la flota o CT2)"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="increasedTireWear" readOnly value={form.increasedTireWear || 20} className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Aumento en el desgaste de los neumáticos debido a la baja presión"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="averageReplacementCost" value={form.averageReplacementCost}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Costo promedio de la sustitución del neumático/reparación"/>
                        </div>
                        
                    </div> 
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Parada no programada (PNP)</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="pnp" value={form.pnp}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Paradas no programadas por mes debido a neumáticos"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="pnpSpent" value={form.pnpSpent}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Gasto promedio relacionado con neumáticos (PNP)"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="deletedPercentageCC" readOnly value={form.deletedPercentageCC || 50}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Porcentaje eliminado por el uso de ContiConnect"/>
                        </div>
                        
                    </div> 
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Consumo de combustible</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="fleetConsumption" value={form.fleetConsumption}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Consumo de la flota (km/litro)"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="dieselPrice" value={form.dieselPrice}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Precio del Diesel (litro)"/>
                        </div>
                        
                    </div> 
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Lucro cesante</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required  name="monthlyBillingPerVehicle" value={form.monthlyBilling}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Facturación mensual por vehículo"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="averageFleetSpeed" value={form.averageFleetSpeed}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Velocidad promedio de la flota (km/h)"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="lostHours" value={form.lostHours}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Horas perdidas por PNP"/>
                        </div>
                        
                    </div> 
                    <div className="container-form">
                        <div className="title-h2">
                            <h2>Inversión inicial ContiConnect</h2>
                            <div className="line">&nbsp;</div>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="numberOfAntennas" value={form.numberOfAntennas}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Número de antenas"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="installationCostPerYardReaders" value={form.installationCostPerYardReaders}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Costo de instalación por Yard Readers"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="monthlyFeePerYardReader" value={form.monthlyFeePerYardReader}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Fee Mensual por Yard Reader"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="numberOfSensors" readOnly value={form.numberOfSensors}  className="input-class text-center w-100" placeholder="Número de sensores"/>
                        </div>
                        <div className="field-modal container-input">
                                <input type="number" step={decimalsAllowed} required name="sensorCost" value={form.sensorCost}  className="input-class text-center w-100" onChange={(event) => {setData(event.target.name, event.target.valueAsNumber); }} placeholder="Costo del sensor"/>
                        </div>
                    </div> 
                    <div className="container-input">
                    <button type="submit" className="btn-action button-init">
                        <span>Generar reporte</span>
                    </button>
                </div>
              </form>
            </div>
            <div className="content-digital">
                <div className="col-12 tires-content">
                    <div  ref={reportTitle} className="title-h2 margin-top">
                        <h2>Reporte</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="col-12 container-tires content-cotizar">
                        <div className="col-12 report content-list-tires">
                            <div ref={report1} className="row">
                                <div className="col-12">
                                    <h4>Datos Generales</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Flota</strong></td>
                                                <td>{form.fleet}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Segmento</strong></td>
                                                <td>{(types.find((type) => type.id.toString() === form?.segment?.toString()))?.description}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Aplicación</strong></td>
                                                <td>{(applications.find((application) => application.id.toString() === form?.application?.toString()))?.description}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Moneda</strong></td>
                                                <td>{form.currency}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <h4>Método Tradicional</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Tiempo de correción de la presión mensual</strong></td>
                                                <td>{output.daysToFixTires1.toFixed(1)} días</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Consumo de tiempo mensual del personal a cargo de neumáticos</strong></td>
                                                <td>{output.staffTimeConsuption1.toFixed(0)}%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Mantenimiento Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.annualMaintenance1.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>PNP Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.anualPNP1.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Pérdida de la vida útil de los neumáticos</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lostValueTireLife1.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Lucro Cesante</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lossProfit1.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Consumo de Combustible</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.fuelConsumption1.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Costo Total Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.totalCost1.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <h4>Con ContiConnect</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Tiempo de correción de la presión mensual</strong></td>
                                                <td>{output.daysToFixTires2.toFixed(1)} días</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Consumo de tiempo mensual del personal a cargo de neumáticos</strong></td>
                                                <td>{output.staffTimeConsuption2.toFixed(0)}%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Mantenimiento Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.annualMaintenance2.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>PNP Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.anualPNP2.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Pérdida de la vida útil de los neumáticos</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lostValueTireLife2.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Lucro Cesante</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lossProfit2.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Consumo de Combustible</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.fuelConsumption2.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Costo Total Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.totalCost2.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center col-12">
                                    <h4>Proyección Ahorro en {form.yearsOfProjection} años</h4>
                                    <h4>Payback: {output.projectionYears.payBack?.toFixed(1)} años</h4>
                                    <div className="col-12 text-center">
                                        <LineChart
                                        width={550}
                                        height={300}
                                        data={chart1}
                                        >
                                            <CartesianGrid fill="#fbfbfb" strokeDasharray="3 3" />
                                            <XAxis style={{
                                                fontSize: '1rem',
                                            }} dataKey="name" />
                                            <YAxis 
                                            style={{
                                                fontSize: '1rem',
                                            }}
                                                tickFormatter={(number) => `${currencySymbol[form.currency]}${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} 
                                            />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" name="Inversión + mensualidades" dataKey="line1" stroke="#FFA400" activeDot={{ r: 8 }} />
                                            <Line type="monotone" name="Ahorro" dataKey="line2" stroke="#2E2C94" />
                                        </LineChart>
                                    </div>
                                    <div className="col-12 text-center">
                                        <BarChart
                                            width={600}
                                            height={300}
                                            layout="vertical"
                                            data={chart2}
                                            margin={{
                                                left: 50,
                                                right: 50
                                            }}
                                            >
                                                <CartesianGrid fill="#fbfbfb" strokeDasharray="3 3" />
                                                <XAxis style={{
                                                fontSize: '1rem',
                                            }} hide axisLine={false} type="number" />
                                                <YAxis style={{
                                                fontSize: '1rem',
                                            }} yAxisId={0}
                                                dataKey="name"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false} />
                                                <YAxis
                                                style={{
                                                    fontSize: '1rem',
                                                }}
                                                tickFormatter={(number) => `${currencySymbol[form.currency]}${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                                orientation="right"
                                                yAxisId={1}
                                                dataKey="pv"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false}

                                                />
                                                <Tooltip />
                                                <Bar name={form.currency} dataKey="pv">
                                                {chart2.map((d, idx) => {
                                                    return <Cell key={d[idx]} fill={d.color} />;
                                                })}
                                                </Bar>
                                        </BarChart>
                                    </div>
                                    <div className="col-12 text-center">
                                        <BarChart
                                            width={740}
                                            height={80}
                                            layout="vertical"
                                            data={chart2_1}
                                            margin={{
                                                top: 1,
                                                left: 120,
                                                right: 50
                                            }}
                                            >
                                                <CartesianGrid fill="#fbfbfb" strokeDasharray="3 3" />
                                                <XAxis style={{
                                                fontSize: '1rem',
                                            }} hide domain={[0, chart2_1[0]?.pv]} axisLine={false} type="number" />
                                                <YAxis style={{
                                                fontSize: '1rem',
                                            }} yAxisId={0}
                                                dataKey="name"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false} />
                                                <YAxis
                                                style={{
                                                    fontSize: '1rem',
                                                }}
                                                tickFormatter={(number) => `${currencySymbol[form.currency]}${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                                orientation="right"
                                                yAxisId={1}
                                                dataKey="pv"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false}

                                                />
                                                <Tooltip />
                                                <Legend />
                                                <Bar name={form.currency} dataKey="pv">
                                                {chart2_1.map((d, idx) => {
                                                    return <Cell key={d[idx]} fill={d.color} />;
                                                })}
                                                </Bar>
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                            <div ref={report2} className="row">
                                <div className="col-6">
                                    <h4>AHORRO</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Tiempo ahorrado</strong></td>
                                                <td><></>{output.daysToFixTires3.toFixed(1)} días</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Ahorro de Tiempo del personal a cargo de neumáticos</strong></td>
                                                <td>{output.staffTimeConsuption3.toFixed(0)}%</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Mantenimiento Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.annualMaintenance3.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>PNP Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.anualPNP3.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Ahorro en la vida útil de los neumáticos</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lostValueTireLife3.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Lucro Cesante</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.lossProfit3.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Consumo de Combustible</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.fuelConsumption3.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong className="yellow-label">Ahorro Anual</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.totalSaving?.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <h4>Información General</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><strong>Total Invertido</strong></td>
                                                <td>{currencySymbol[form.currency]}{output.totalInvestment.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td><strong>PayBack</strong></td>
                                                <td>{output.projectionYears.payBack?.toFixed(1)} años</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Tiempo para cambiar todos los neumáticos de la Flota</strong></td>
                                                <td>{output.projectionMonthly.completeMonthlyTireChange.toFixed(0)} meses</td>
                                            </tr>
                                            <tr>
                                                <td><strong className="yellow-label">Producto</strong></td>
                                                <td>{form.product}</td>
                                            </tr>
                                            <tr>
                                                <td><strong className="yellow-label">Valor ahorrado revertido en neumáticos Inteligentes al año</strong></td>
                                                <td>{Math.floor(output.totalSaving?.toFixed(2)/form.productValue)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center col-12">
                                    <h4>Reemplazo en {output.projectionMonthly.completeMonthlyTireChange} meses</h4>
                                    <div className="col-12 text-center">
                                        <LineChart
                                        width={550}
                                        height={300}
                                        data={chart3}
                                        >
                                            <CartesianGrid fill="#fbfbfb" strokeDasharray="3 3" />
                                            <XAxis style={{
                                                fontSize: '1rem',
                                            }} dataKey="name" />
                                            <YAxis style={{
                                                fontSize: '1rem',
                                            }} tickFormatter={(number) => `${currencySymbol[form.currency]}${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" name="Total por cambio de neumáticos" dataKey="line1" stroke="#FFA400" activeDot={{ r: 8 }} />
                                            <Line type="monotone" name="Meses" dataKey="line2" stroke="#2E2C94" />
                                        </LineChart>
                                    </div>
                                    <div className="col-12 text-center">
                                        <BarChart
                                            width={620}
                                            height={300}
                                            layout="vertical"
                                            margin={{
                                                left: 50,
                                                right: 20
                                            }}
                                            data={chart4}
                                            >
                                                <CartesianGrid fill="#fbfbfb" strokeDasharray="3 3" />
                                                <XAxis style={{
                                                fontSize: '1rem',
                                            }} hide axisLine={false} type="number" />
                                                <YAxis style={{
                                                fontSize: '1rem',
                                            }} yAxisId={0}
                                                dataKey="name"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false} />
                                                <YAxis
                                                style={{
                                                    fontSize: '1rem',
                                                }}
                                                orientation="right"
                                                yAxisId={1}
                                                dataKey="pv"
                                                type="category"
                                                axisLine={false}
                                                tickLine={false}
                                                tickFormatter={(number) => `${currencySymbol[form.currency]}${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}

                                                />
                                                <Tooltip />
                                                <Legend />
                                                <Bar name={form.currency} dataKey="pv">
                                                {chart4.map((d, idx) => {
                                                    return <Cell key={d[idx]} fill={d.color} />;
                                                })}
                                                </Bar>
                                        </BarChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center  tires-content">
                    <button onClick={() => downloadPdf()} className="btn-action border-100 button-init small-icon">
                        <span>Descargar</span>
                        <img src="/img/icons/icon_download.svg" className="img-responsive" alt="Cart"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {DigitalSolutions};