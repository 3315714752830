import React, {useState, useEffect} from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {createInspectionsReviews, getInspectionsReviews} from './apiInspectionsReviews';
import {getInspectionsBrands} from '../InspectionsBrand/apiInspectionsBrands';
import {getInspectionsProducts} from '../InspectionsProduct/apiInspectionsProducts';
import {getInspectionsDatabooks} from '../InspectionsDatabook/apiInspectionsDatabooks';
import {getInspectionsVehicles} from '../InspectionsVehicle/apiInspectionsVehicles';
import {getInspectionsPositions} from '../InspectionsPosition/apiInspecionsPositions';
import ReactTooltip from 'react-tooltip';

import {Helmet} from 'react-helmet';

const InspectionsReviewForm = () => {
  const [searchParams] = useSearchParams();
  const decimalsAllowed = '.1';
  const history = useNavigate();
  let query = useLocation();
  query = query.pathname.split('/');
  const positionId  = query[2];
  const [review, setReview] = useState({cvt_inspections_position_id: positionId});
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [databooks, setDatabooks] = useState([]);
  const [products, setProducts] = useState([]);
  const [shellBrandId, setShellBrandId] = useState(null);
  const [shellProductId, setShellProductId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [depth ,setDepth] = useState(null);
  const [vehicle ,setVehicle] = useState({});
  const [nextPosition ,setNextPosition] = useState(0);
  const fetchAll = async () => {
    const _brands_ = await getInspectionsBrands();
    setBrands(_brands_);
    const _products_ = await getInspectionsProducts();
    setProducts(_products_);
    const _databooks_ = await getInspectionsDatabooks();
    setDatabooks(_databooks_);
    const _vehicle_ = await getInspectionsVehicles({license_plate: searchParams.get('license_plate')});
    const fetchedPositions = await getInspectionsPositions({cvt_inspections_vehicle_id: _vehicle_[0].id});
    const nextPosition = fetchedPositions.find(position => position.id > positionId);
    setNextPosition(nextPosition);
    setVehicle(_vehicle_[0]);
    const reviews = await getInspectionsReviews({cvt_inspections_position_id: positionId});
    let _review_ = reviews[0];
    if(_review_) {
        setBrandId(_review_.cvt_inspections_databook.cvt_inspections_product.cvt_inspections_brand_id);
        setProductId(_review_.cvt_inspections_databook.cvt_inspections_product.id);
        setDepth(_review_.cvt_inspections_databook.depth);
        if(_review_.cvt_inspections_shell_databook_id) {
            setShellProductId(_review_.cvt_inspections_shell_databook.cvt_inspections_product_id);
            setShellBrandId(_review_.cvt_inspections_shell_databook.cvt_inspections_product.cvt_inspections_brand_id);
        }
        _review_ = calculateFields(_review_);
        setReview(_review_);
    }


  }
  const calculateFields = (newReview) => {
    newReview.wear = Math.round(100-100*(newReview.mm_min-newReview.mm_retirement)/(depth-newReview.mm_retirement));
    newReview.km_actual_route = newReview.km_actual-newReview.km_initial;
    newReview.km_mm = newReview.km_actual_route/(depth-newReview.mm_min);
    newReview.km_projected = newReview.km_mm*(depth-newReview.mm_retirement);
    newReview.km_cost = newReview.tire_cost/newReview.km_projected;
    newReview.km_100_cost = 1000*newReview.km_cost;
    return newReview;
  }
  const setData = (position, value) => {
    let newReview = {...review}
    newReview[position] = value;
    newReview.mm_retirement = vehicle.mm_retirement;
    const onlyValues = [newReview.mm_1, newReview.mm_2, newReview.mm_3, newReview.mm_4].filter((a) => a).map(a => parseFloat(a));
    newReview.mm_min = Math.min(...onlyValues);
    newReview.km_actual = vehicle.km_initial;
    newReview = calculateFields(newReview);
    if(newReview.mm_min > newReview.mm_retirement + 3) {
        newReview.reference = 'BUEN ESTADO';
    }else{
        if(newReview.mm_min > newReview.mm_retirement) {
            newReview.reference = 'PROXIMO A RETIRAR';
        }else{
            newReview.reference = 'RETIRAR';
        }
    }

    setReview(newReview);
  }
  const save = async (event) => {
    console.log(review);
    event.preventDefault();
    await createInspectionsReviews(review);
    history(`/inspections_position/${positionId}?license_plate=${searchParams.get('license_plate')}&positionDescription=${searchParams.get('positionDescription')}`);
  };
  const saveAndNext = async () => {
    await createInspectionsReviews(review);
    history(`/create_inspections_review/${nextPosition.id}?license_plate=${searchParams.get('license_plate')}&positionDescription=${nextPosition.description}`);
    history(0);
  };
  useEffect(() => {
    fetchAll();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Crear Vehículo</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>Crear revisión vehículo {searchParams.get('license_plate')} - posición {searchParams.get('positionDescription')} </span>
        </div>
        <div className="header-callaction">
            <button onClick={() => history(-1)} className="close-header close-al" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="">
                <form onSubmit={save}>
                  <div className="container-form">
                      <div className="title-h2">
                          <h2>Datos de la revisión</h2>
                          <div className="line">&nbsp;</div>
                      </div>
                      <div className="field-modal container-input">
                      <label>Marca</label>
                        <select required value={brandId} onChange={(event) => {setBrandId(event.target.value)}} className="input-class text-center">
                              <option>*Marca</option>
                              {brands.map((brand) => {
                                  return(<option key={brand.id} value={brand.id}>{brand.description}</option>)
                              })}
                        </select>

                      </div>
                      <div className="field-modal container-input">
                      <label>Producto</label>
                      <select required value={productId} onChange={(event) => setProductId(event.target.value)} className="input-class text-center">
                              <option>*Producto</option>
                              {products.filter((product)=> product.cvt_inspections_brand_id?.toString() === brandId?.toString()).map((brand) => {
                                  return(<option key={brand.id} value={brand.id}>{brand.description}</option>)
                              })}
                        </select>
                      </div>
                      <div className="field-modal container-input">
                      <label>Medida</label>
                      <select required value={review.cvt_inspections_databook_id} onChange={(event) => {setData('cvt_inspections_databook_id', event.target.value); setDepth(databooks.find((databook) => databook.id?.toString() === event.target.value)?.depth)}} className="input-class text-center">
                              <option>*Medida</option>
                              {databooks.filter((databook)=> databook.cvt_inspections_product_id?.toString() === productId?.toString()).map((databook) => {
                                  return(<option key={databook.id} value={databook.id}>{databook.mesure}</option>)
                              })}
                        </select>
                      </div>
                      {(brands.find((brand) => brand.id.toString() === brandId?.toString()))?.description?.toLowerCase()?.includes('tread') && <div>
                        <div className="field-modal container-input">
                        <label>Marca de casco</label>
                            <select value={shellBrandId} onChange={(event) => setShellBrandId(event.target.value)} className="input-class text-center">
                                <option>*Marca</option>
                                {brands.map((brand) => {
                                    return(<option key={brand.id} value={brand.id}>{brand.description}</option>)
                                })}
                            </select>

                        </div>
                        <div className="field-modal container-input">
                        <label>Producto de casco</label>
                        <select value={shellProductId} onChange={(event) => setShellProductId(event.target.value)} className="input-class text-center">
                                <option>*Producto</option>
                                {products.filter((product) => product.type === 'NEW').filter((product)=> product.cvt_inspections_brand_id?.toString() === shellBrandId?.toString()).map((brand) => {
                                    return(<option key={brand.id} value={brand.id}>{brand.description}</option>)
                                })}
                            </select>
                        </div>
                        <div className="field-modal container-input">
                        <label>Medida de casco</label>
                        <select value={review.cvt_inspections_shell_databook_id} onChange={(event) => {setData('cvt_inspections_shell_databook_id', event.target.value); }} className="input-class text-center">
                                <option>*Medida</option>
                                {databooks.filter((databook)=> databook.cvt_inspections_product_id?.toString() === shellProductId?.toString()).map((databook) => {
                                    return(<option key={databook.id} value={databook.id}>{databook.mesure}</option>)
                                })}
                            </select>
                        </div>
                      </div> }

                      <div className="field-modal container-input">
                          <label>Labrado original</label>
                          <input type="number" step={decimalsAllowed} value={depth} readOnly className="input-class text-center w-100"  placeholder="Labrado original" data-tip="Labrado original"/>
                      </div>
                      <div className="field-modal container-input">
                          <label># de reencauche</label>
                          <input type="number" step={decimalsAllowed} value={review.tread_number} onChange={(event) => setData('tread_number', event.target.value)} className="input-class text-center w-100"  placeholder="# de reencauche" data-tip="# de reencauche"/>
                      </div>
                      <div className="field-modal container-input">
                          <label>MM 1</label>
                          <input type="number" step={decimalsAllowed} value={review.mm_1} onChange={(event) => setData('mm_1', event.target.value)} className="input-class text-center w-100"  placeholder="MM 1" data-tip="MM 1"/>
                      </div>
                      <div className="field-modal container-input">
                            <label>MM 2</label>
                          <input type="number" step={decimalsAllowed} value={review.mm_2} onChange={(event) => setData('mm_2', event.target.value)} className="input-class text-center w-100"  placeholder="MM 2" data-tip="MM 2"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>MM 3</label>
                          <input type="number" step={decimalsAllowed} value={review.mm_3} onChange={(event) => setData('mm_3', event.target.value)} className="input-class text-center w-100"  placeholder="MM 3" data-tip="MM 3"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>MM 4</label>
                          <input type="number" step={decimalsAllowed} value={review.mm_4} onChange={(event) => setData('mm_4', event.target.value)} className="input-class text-center w-100"  placeholder="MM 4" data-tip="MM 4"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>MM MIN</label>
                          <input type="number" step={decimalsAllowed} readOnly value={review.mm_min} className="input-class text-center w-100"  placeholder="MM MIN" data-tip="MM MIN"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>MM Retiro</label>
                          <input type="number" step={decimalsAllowed} value={vehicle?.mm_retirement} readOnly className="input-class text-center w-100"  placeholder="MM Retiro" data-tip="MM Retiro"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Desgaste</label>
                          <input type="number" step={decimalsAllowed} readOnly value={review.wear}  className="input-class text-center w-100"  placeholder="Desgaste" data-tip="Desgaste"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Psi</label>
                          <input type="number" step={decimalsAllowed} value={review.psi} onChange={(event) => setData('psi', event.target.value)} className="input-class text-center w-100"  placeholder="Psi"  data-tip="Psi"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Referencia</label>
                          <input type="text" value={review.reference} readOnly  className="input-class text-center w-100"  placeholder="Referencia" data-tip="Referencia"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Ruta</label>
                          <input type="text" value={review.route} onChange={(event) => setData('route', event.target.value)} className="input-class text-center w-100"  placeholder="Ruta"  data-tip="Ruta"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Térmico</label>
                          <input type="text" value={review.thermal} onChange={(event) => setData('thermal', event.target.value)} className="input-class text-center w-100"  placeholder="Térmico" data-tip="Térmico"/>
                      </div>
                      <div className="field-modal container-input">
                      <select value={review.status} onChange={(event) => {setData('status', event.target.value);}} className="input-class text-center">
                      <label>Estado</label>
                            <option>Estado</option>
                            <option value="Inspección sin novedades">Inspección sin novedades</option>
                            <option value="Rendimiento final">Rendimiento final</option>
                            <option value="Incidente">Incidente</option>
                            <option value="Montaje nuevo">Montaje nuevo</option>
                        </select>
                        </div>
                      <div className="field-modal container-input">
                      <label>Digital Solutions</label>
                      <select value={review.digital_solutions} onChange={(event) => {setData('digital_solutions', event.target.value);}} className="input-class text-center">
                            <option>Digital Solutions</option>
                            <option value="NO">NO</option>
                            <option value="CPC">CPC</option>
                            <option value="CONTICONNECT">CONTI CONNECT</option>
                        </select>
                        </div>
                      <div className="field-modal container-input">
                      <label>Sensor</label>
                      <select value={review.sensor} onChange={(event) => {setData('sensor', event.target.value);}} className="input-class text-center">
                            <option>Sensor</option>
                            <option value="NO">NO</option>
                            <option value="SI">SI</option>
                        </select>
                        </div>
                      <div className="field-modal container-input">
                      <label>Observaciones</label>
                          <input type="text" value={review.observations} onChange={(event) => setData('observations', event.target.value)} className="input-class text-center w-100"  placeholder="Observaciones" data-tip="Observaciones"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Km de montaje</label>
                          <input type="number" value={review.km_initial} onChange={(event) => setData('km_initial', event.target.value)} className="input-class text-center w-100"  placeholder="Km de montaje" data-tip="Km de montaje"/>
                      </div>
                      <div className="field-modal container-input">
                        <label>Km actual</label>
                          <input type="number" value={vehicle.km_initial} readOnly className="input-class text-center w-100"  placeholder="Km actual" data-tip="Km actual"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Km actual recorrido</label>
                          <input type="number" readOnly value={review.km_actual_route} onChange={(event) => setData('km_actual_route', event.target.value)} className="input-class text-center w-100"  placeholder="Km actual recorrido" data-tip="Km actual recorrido"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Km proyectado</label>
                          <input type="number" readOnly value={review.km_projected} onChange={(event) => setData('km_projected', event.target.value)} className="input-class text-center w-100"  placeholder="Km proyectado" data-tip="Km proyectado"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Costo llanta</label>
                          <input type="number" value={review.tire_cost} onChange={(event) => setData('tire_cost', event.target.value)} className="input-class text-center w-100"  placeholder="Costo llanta" data-tip="Costo llanta"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Costo por Km</label>
                          <input type="number" readOnly value={review.km_cost} onChange={(event) => setData('km_cost', event.target.value)} className="input-class text-center w-100"  placeholder="Costo por Km" data-tip="Costo por KM"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Costo por 1000 Km</label>
                          <input type="number" readOnly value={review.km_100_cost} onChange={(event) => setData('km_100_cost', event.target.value)} className="input-class text-center w-100"  placeholder="Costo por 1000 Km" data-tip="Costo por 100 KM"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>KM MM</label>
                          <input type="number" data-tip="KM MM" readOnly value={review.km_mm} onChange={(event) => setData('km_mm', event.target.value)} className="input-class text-center w-100"  placeholder="KM MM"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Fecha de montaje</label>
                          <input type="date" data-tip="Fecha de montaje" value={review.assembly_date} onChange={(event) => setData('assembly_date', event.target.value)} className="input-class text-center w-100"  placeholder="Fecha de montaje"/>
                          <ReactTooltip />
                      </div>
                      <div className="field-modal container-input">
                      <label>DOT</label>
                          <input type="text" data-tip="DOT" value={review.dot} onChange={(event) => setData('dot', event.target.value)} className="input-class text-center w-100"  placeholder="DOT"/>
                          <ReactTooltip />
                      </div>

                  </div> 
                  <div className="container-input">
                  <button type="submit" className="btn-action button-init">
                      <span>Guardar</span>
                  </button>
              </div>
                  <div className="container-input">
                  <button type="button"  onClick={() => saveAndNext()} className="btn-action button-init">
                      <span>Guardar y Continuar con siguiente posición</span>
                  </button>
              </div>
                </form>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {InspectionsReviewForm};