import React, {useEffect, useState} from "react";
import { useNavigate, useLocation, useSearchParams  } from "react-router-dom";
import {getInspectionsPositions} from '../InspectionsPosition/apiInspecionsPositions';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import {baseUrlImages} from '../config';

const InspectionsVehicle = () => {
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const [positions, setPositions] = useState([]);
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];

  const fetchPositions = async () => {

    const fetchedPositions = await getInspectionsPositions({cvt_inspections_vehicle_id: id});
    setPositions(fetchedPositions)
  }
  useEffect(() => {
    fetchPositions();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Vehículo - {searchParams.get('license_plate')}</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>{searchParams.get('license_plate')}</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => history(-1)} className="close-header close-al" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
                <div className="container-tires ContiTips">
                  { positions.map((position, key) => {
                    return(
                    <div key={key} className="container-tips col-12 col-sm-6 col-md-4">
                      <div className="content-tip-text-full-border">
                          <div className="information">
                              <h4>{position.description}</h4>
                              <p>{position.position?.description} </p>
                          </div>
                          <div className="content-modal-button">
                            <Link to={{pathname:`/inspections_position/${position.id}?positionDescription=${position.description}&license_plate=${searchParams.get('license_plate')}&fleetId=${searchParams.get('fleetId')}&vehicleId=${id}`}}>
                              <button className="btn-action border-100 button-init small-icon">
                                  <span>Ver inspecciones</span>
                                  <img src="/img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                              </button>
                            </Link>
                          </div>
                      </div>

                    </div>
                  )})
                    }
                    <div className="container-tips content-tip-add">
                    <Link className="width-100" to={`/create_inspections_position/${id}?license_plate=${searchParams.get('license_plate')}`}>
                      <div className="add-new"><div className="add-container">+</div></div>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {InspectionsVehicle};