import React, {useEffect} from "react";
import lottie from 'lottie-web';
import {
  Link
} from "react-router-dom";
import {useAuth} from '../Auth/authContext';
import {Helmet} from 'react-helmet';
const Home = () => {
  const auth = useAuth();
  useEffect(() => {
    let anim_tireselector;
    let anim_ficha;
    let anim_cotizador;
    let anim_technical;
    let anim_renovado;
    let anim_contitips;
    let anim_continews;
    let anim_digital;

    const params_tireselector = {
        container: document.getElementById('json_tireselector'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/tireselector.json'
    };

    const params_ficha = {
        container: document.getElementById('json_ficha'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/fichatecnica.json'
    };
    const params_cotizador = {
        container: document.getElementById('json_cotizador'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/cotizador.json'
    };
    const params_technical = {
        container: document.getElementById('json_technical'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/technicalsupport.json'
    };
    const params_renovado = {
        container: document.getElementById('json_renovado'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/renovado.json'
    };
    const params_contitips = {
        container: document.getElementById('json_contitips'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/contitips.json'
    };
    const params_continews = {
        container: document.getElementById('json_continews'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/continews.json'
    };
    const params_digital = {
        container: document.getElementById('json_digital'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/digital.json'
    };

    
    anim_tireselector = lottie.loadAnimation(params_tireselector);
    anim_ficha = lottie.loadAnimation(params_ficha);
    anim_cotizador = lottie.loadAnimation(params_cotizador);
    anim_technical = lottie.loadAnimation(params_technical);
    anim_renovado = lottie.loadAnimation(params_renovado);
    anim_contitips = lottie.loadAnimation(params_contitips);
    anim_continews = lottie.loadAnimation(params_continews);
    anim_digital = lottie.loadAnimation(params_digital);

    const btn_tireselector =  document.querySelector(".tireselector");
    const btn_ficha =  document.querySelector(".fichatecnica");
    const btn_cotizador =  document.querySelector(".cotizador");
    const btn_technical =  document.querySelector(".technical");
    const btn_renovado =  document.querySelector(".renovado");
    const btn_contitips =  document.querySelector(".contitips");
    const btn_continews =  document.querySelector(".continews");
    const btn_digital =  document.querySelector(".digital-solutions");

    btn_tireselector.addEventListener('mouseenter',()=>{
      anim_tireselector.play();
    });
    btn_tireselector.addEventListener('mouseleave',()=>{
      anim_tireselector.stop();
    });

    btn_ficha.addEventListener('mouseenter',()=>{
      anim_ficha.play();
    });
    btn_ficha.addEventListener('mouseleave',()=>{
      anim_ficha.stop();
    });

    btn_cotizador.addEventListener('mouseenter',()=>{
      anim_cotizador.play();
    });
    btn_cotizador.addEventListener('mouseleave',()=>{
      anim_cotizador.stop();
    });

    btn_technical.addEventListener('mouseenter',()=>{
      anim_technical.play();
    });
    btn_technical.addEventListener('mouseleave',()=>{
      anim_technical.stop();
    });

    btn_renovado.addEventListener('mouseenter',()=>{
      anim_renovado.play();
    });
    btn_renovado.addEventListener('mouseleave',()=>{
      anim_renovado.stop();
    });

    btn_contitips.addEventListener('mouseenter',()=>{
      anim_contitips.play();
    });
    btn_contitips.addEventListener('mouseleave',()=>{
      anim_contitips.stop();
    });

    btn_continews.addEventListener('mouseenter',()=>{
      anim_continews.play();
    });
    btn_continews.addEventListener('mouseleave',()=>{
      anim_continews.stop();
    });
    btn_digital.addEventListener('mouseenter',()=>{
      anim_digital.play();
    });
    btn_digital.addEventListener('mouseleave',()=>{
      anim_digital.stop();
    });
  });
  return (
    <div>
      <Helmet>
          <title>TruckTire - Home</title>
      </Helmet>
      <div className="container-buttons">
        <Link to={'/tireselector'} className="button-home tireselector">
          <div id='json_tireselector' className="lottie-icon"></div>
        </Link>
        <Link to={'/products'} className="button-home fichatecnica">
          <div id='json_ficha' className="lottie-icon"></div>
        </Link>
        <Link to={'/treads'} className="button-home renovado">
          <div id='json_renovado' className="lottie-icon"></div>
        </Link>
        <Link to={'/quoter'} className="button-home cotizador">
          <div id='json_cotizador' className="lottie-icon"></div>
        </Link>
        <Link to={'/digital_solutions'} className="button-home digital-solutions">
          <div id='json_digital' className="lottie-icon"></div>
        </Link>
        <Link to={'/support'} className="button-home technical">
          <div id='json_technical' className="lottie-icon"></div>
        </Link>
        <Link to={'/tips'} className="button-home contitips">
          <div id='json_contitips' className="lottie-icon"></div>
        </Link>
        <Link to={'/news'} className="button-home continews">
          <div id='json_continews' className="lottie-icon"></div>
        </Link>
        <Link to={'#'} className="button-home empty">
        </Link>
      </div>
      <div className="tire-home-vertical"><img src={`/img/${auth.user?.data?.country_id || 1}_home_tire.png`}  alt=""/></div>
    </div>
  );
}
export {Home};