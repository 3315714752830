import React, {} from "react";
import {Link} from "react-router-dom";
import {baseUrlImages} from '../config';
import { setCart, existsInCart, getCart } from "../Quoter/quoterProvider";

const ProductContainer =  ({product, databooks, refreshCart, simpleAdd}) => {
  let color = 'yellow';
  if(product.brand_id === 2) {
    color = 'red';
  }
  if(product.brand_id === 3) {
    color = 'blue';
  }
 return (                        
 <div className="container-tire col-12 col-sm-4">
 <Link to={simpleAdd ? '#' : `/${product.type === 'CONTITREAD' ? 'tread' : 'product'}/${product.id}`}>
     <div className="img-tire">
         <img src={`${baseUrlImages}/${product.picture}`} alt="" className="img-responsive" />
     </div>
     <div className="aplications justify-content-start">
     {product.applications.map((application, k) => {
     return (
       <div key={`${product.id}-${application.id}${k}`} className="img-aplications img-aplications-tires">
         <img src={`/img/aplications/${application.picture}`} alt="" className="img-responsive" />
       </div>
     )
   })
   }
     </div>
 </Link>
 <div className="content-information">
     <div className={`title-information ${!databooks ? 'border-complete' : ''}`}>
         <div className={`circle-color ${color}`}>&nbsp;</div>
         <span>{product.description}</span>
     </div>
     {databooks?.map((databook, k) => {
        const data = databook.cvt_databook;
        data.price = databook.price;
        data.productPicture = product.picture;
        data.productDescription = product.description;
        data.brand_id = product.brand_id;

        return(<div key={`${k}-${product.id}`} className={`cart-information ${k+1 === databooks.length ? 'border-complete' : ''}`}>
        <span>{data.mesure} {data.lr} {data.tl_tt} <br/> {data.price ? `$${data.price}` : ''}</span>
        {!existsInCart(data.id) && !simpleAdd && <a className="icon_cart" onClick={() => {setCart(data); if(refreshCart) {refreshCart(getCart());}}} href="#"><img src="img/icons/icon_cart_plus.svg" className="img-responsive" alt="Cart"/></a>}
        {simpleAdd && <a className="icon_cart" onClick={() => {simpleAdd(data, data.price);}} href="#"><img src="img/icons/icon_inspections_white.svg" className="img-responsive" alt="Cart"/></a>}
    </div>)
     })}
 </div>
</div>)
}
export {ProductContainer};