import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {getContents} from "../Content/apiContent";
import {baseUrlImages} from '../config';
import {Helmet} from 'react-helmet';
const getDateFromText = (str) => {
  const result = str.match(/<b>(.*?)<\/b>/g).map(function(val){
    return val.replace(/<\/?b>/g,'');
 });
 return result;
}
const getSize = (str) => {
  const result = str.match(/<size=28>(.*?)<\/size>/g).map(function(val){
    return val.replace(/<\/?size>/g,'');
 });
 return result;
}

const News = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [contents, setContents] = useState([]);

  const fetchContent = async () => {
    const fetchedContents = await getContents({type: 'NEWS'});
    setContents(fetchedContents)
  }
  useEffect(() => {
    fetchContent();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Noticias</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="./img/icons/icon_continews_white.svg" className="img-responsive" alt="Cart"/>
            <span>ContiNews</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
                <img src="./img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
                <div className="container-tires ContiTips">
                  { contents.map((content) => {
                    return(
                    <div key={content.id} className="container-tips conti-tips col-12 col-md-6 col-lg-4">
                        <div className="img-tip">
                            <img src={`${baseUrlImages}/${content.picture}`} alt="" className="img-responsive" />
                        </div>
                        <div className="title-continews">
                            {content.title}
                        </div>
                        <div className="content-tip-text">
                            <div className="information">
                                <h4>{getDateFromText(content.description)}</h4>
                                <p>{content.description.replace(getSize(content.description), "").replace(/(<([^>]+)>)/gi, "")}</p>
                            </div>
                            {content.pdf && 
                        <div className="content-modal-button">
                            <a href={`${baseUrlImages}/${content.pdf}`}>
                            <button className="btn-action border-100 button-init small-icon">
                                <span>Descargar</span>
                                <img src="./img/icons/icon_arrow_right.svg" className="img-responsive" alt="Cart"/>
                            </button>
                            </a>
                        </div>
                        }
                        </div>

                    </div>
                  )})
                    }
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {News};