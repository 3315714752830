export const groupBy = (arr, criteria) => {
  const newObj = arr.reduce((acc, currentValue) => {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}
export const pluck = (array, key) => {
  return array.map(o => o[key]);
}
export const sortProduct = (a,b) => {
  if (a.brand_id < b.brand_id)
     return -1;
  if (a.brand_id > b.brand_id)
    return 1;
  return 0;
}
export const round = (num, dec) => {
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
};