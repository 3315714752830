import React, {useState, useEffect} from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {createInspectionsPositions} from './apiInspecionsPositions';
import {getPositions} from '../Position/apiPositions';

import {Helmet} from 'react-helmet';

const InspectionsPositionForm = () => {
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  let query = useLocation();
  query = query.pathname.split('/');
  const vehicleId  = query[2];

  const [position, setPosition] = useState({cvt_inspections_vehicle_id: vehicleId});
  const [positions, setPositions] = useState([]);
  const fetchAll = async () => {
    const _positions_ = await getPositions();
    setPositions(_positions_);

  }
  const setData = (_position_, value) => {
  console.log("🚀 ~ file: InspectionsPositionForm.js ~ line 25 ~ setData ~ position", position);
    const newPosition = {...position}
    newPosition[_position_] = value;
    console.log("🚀 ~ file: InspectionsPositionForm.js ~ line 27 ~ setData ~ newPosition", newPosition);
    setPosition(newPosition);
  }
  const save = async (event) => {
    event.preventDefault();
    console.log(position);
    await createInspectionsPositions(position);
    history(`/inspections_vehicle/${vehicleId}?license_plate=${searchParams.get('license_plate')}`);
  };
  useEffect(() => {
    fetchAll();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Crear Posición</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>Crear posición</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => history(-1)} className="close-header close-al" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="">
                <form onSubmit={save}>
                  <div className="container-form">
                      <div className="title-h2">
                          <h2>Datos de la posición para el vehículo {searchParams.get('license_plate')}</h2>
                          <div className="line">&nbsp;</div>
                      </div>
                      <div className="field-modal container-input">
                              <input type="number" value={position.description} onChange={(event) => setData('description', event.target.value)} className="input-class text-center w-100" required placeholder="# de posición"/>
                      </div>
                      <div className="field-modal container-input">
                        <select required onChange={(event) => setData('position_id', event.target.value)} className="input-class text-center">
                              <option>*Posición</option>
                              {positions.map((position) => {
                                  return(<option key={position.id} value={position.id}>{position.description}</option>)
                              })}
                        </select>
                      </div>

                  </div> 
                  <div className="container-input">
                  <button type="submit" className="btn-action button-init">
                      <span>Guardar</span>
                  </button>
              </div>
                </form>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {InspectionsPositionForm};