import React, {useEffect, useState} from "react";
import { useNavigate, useLocation, useSearchParams  } from "react-router-dom";
import {getInspectionsReviews} from '../InspectionsReview/apiInspectionsReviews';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import {baseUrlImages} from '../config';

const InspectionsPosition = () => {
  const headers = ['Posición', 'Eje', 'ID Vehículo', 'Producto', 
  'Tipo (nuevo o reencauche)', 'Medida', 'mm1', 'mm2', 
  'mm3', 'mm4', 'desgaste','psi',
  'referencia', 'KM Inicial', 'Km actual', 'KM actual ruta', 
  'Km Proyectado', 'Costo Llanta', 'Costo por KM', 'Costo por 100 KM',
  'Ruta', 'Observaciones', 'KM MM', 'MM min', 'MM retiro', 'Fecha de montaje',
  'Fecha de revisión'];
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const [reviews, setReviews] = useState([]);
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];

  const fetchReviews = async () => {
    const fetchedReviews = await getInspectionsReviews({cvt_inspections_position_id: id});
    setReviews(fetchedReviews)
  }
  useEffect(() => {
    fetchReviews();
}, []);

  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Vehículo - {searchParams.get('license_plate')} - posición - {searchParams.get('positionDescription')}</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>{searchParams.get('license_plate')}- posición {searchParams.get('positionDescription')}</span>
        </div>
        <div className="header-callaction search-products">
            <input placeholder="Buscar" className="input-class" />
            <button onClick={() => history(-1)} className="close-header">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    { headers.map((header, key) => {
                      return <th key={key} scope="col">{header}</th>
                    })
                    } 
                  </tr>
                </thead>
                <tbody>
                  {reviews.map((review, key) => {
                    return (<tr key={key}>
                      <th scope="row">{review?.cvt_inspections_position?.description}</th>
                      <td>{review.cvt_inspections_position.position.description}</td>
                      <td>{review.cvt_inspections_position.cvt_inspections_vehicle.license_plate}</td>
                      <td>{review.cvt_inspections_databook.cvt_inspections_product.description}</td>
                      <td>{review.cvt_inspections_databook.cvt_inspections_product.type}</td>
                      <td>{review.cvt_inspections_databook.mesure}</td>
                      <td>{review.mm_1}</td>
                      <td>{review.mm_2}</td>
                      <td>{review.mm_3}</td>
                      <td>{review.mm_4}</td>
                      <td>{review.wear}</td>
                      <td>{review.psi}</td>
                      <td>{review.reference}</td>
                      <td>{review.km_initial}</td>
                      <td>{review.km_actual}</td>
                      <td>{review.km_actual_route}</td>
                      <td>{review.km_projected}</td>
                      <td>{review.tire_cost}</td>
                      <td>{review.km_cost}</td>
                      <td>{review.km_100_cost}</td>
                      <td>{review.route}</td>
                      <td>{review.observations}</td>
                      <td>{review.km_mm}</td>
                      <td>{review.mm_min}</td>
                      <td>{review.mm_retirement}</td>
                      <td>{review.assembly_date}</td>
                      <td>{review.created_at}</td>
                    </tr>)
                  })
                  }
                  <tr><th><Link className="btn-action border-100 button-init small-icon" to={`/create_inspections_review/${id}?license_plate=${searchParams.get('license_plate')}&positionDescription=${searchParams.get('positionDescription')}`}>Nueva revisión</Link></th></tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
  </div>
  );
}
export {InspectionsPosition};