import React, {useEffect, useState} from "react";
import {getProductTreads} from "./apiTreads";
import { useNavigate } from "react-router-dom";
import {ProductContainer} from "../Product/ProductContainer";
import {Search} from '../Search/Search';
import {Helmet} from 'react-helmet';
const Treads = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route)
  }
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const fetchProducts = async () => {
    const fetchedProducts = await getProductTreads();
    setProducts(fetchedProducts);
    setOriginalProducts(fetchedProducts);
  }
  useEffect(() => {
  fetchProducts();
}, []);
  return (
    <div className="container-page">
      <Helmet>
          <title>TruckTire - Treads</title>
      </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_fichatecnica_white.svg" className="img-responsive" alt="Cart"/>
            <span>Ficha t&eacute;cnica</span>
        </div>
        <div className="header-callaction search-products">
            <Search products={products} originalProducts={[...originalProducts]} filterProducts={setProducts} />
            <button onClick={() => handleClick()} className="close-header">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row no-gutters">
            <div className="col-12">
                <div className="col-12 txt-2columns">
                    <p>Agregar un filtro para depurar la visualización de llantas. Selecciona la que se adapte mejor a tu vehículo y tus exigencias.</p>
                </div>
                <div className="container-tires row no-gutters">
                { products.map((product) => {
                  return (<ProductContainer key={product.id} product={product} />)
                })
                }
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {Treads};