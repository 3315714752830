import React, {useState, useEffect} from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {getInspectionsVehicles, createInspectionsVehicles, updateInspectionsVehicles} from '../InspectionsVehicle/apiInspectionsVehicles';
import {getInspectionsConfigurations} from '../InspectionsConfiguration/apiInspectionsConfigurations';
import {getInspectionsVehicleBrands} from '../InspectionsVehicleBrand/apiInspectionsVehicleBrands';
import {getInspectionsSegments} from '../InspectionsSegment/apiInspectionsSegments';
import {getInspectionsTypes} from '../InspectionsType/apiInspectionsTypes';
import {getApplications} from '../Application/apiApplications';

import {Helmet} from 'react-helmet';

const InspectionsVehicleForm = () => {
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  let query = useLocation();
  query = query.pathname.split('/');
  const fleetId  = query[2];

  const [vehicle, setVehicle] = useState({cvt_inspections_fleet_id: fleetId});
  const [configurations, setConfigurations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [segments, setSegments] = useState([]);
  const [applications, setApplications] = useState([]);
  const fetchAll = async () => {
    if(searchParams.get('vehicleId')) {
      const vehicles = await getInspectionsVehicles({id: searchParams.get('vehicleId')});
      const _vehicle_ = vehicles[0];
      setVehicle(_vehicle_);
    }
    const _configurations_ = await getInspectionsConfigurations();
    setConfigurations(_configurations_);
    const _brands_ = await getInspectionsVehicleBrands();
    setBrands(_brands_);
    const _types_ = await getInspectionsTypes();
    setTypes(_types_);
    const _segments_ = await getInspectionsSegments();
    setSegments(_segments_);
    const _appliations = await getApplications();
    setApplications(_appliations);

  }
  const setData = (position, value) => {
    const newVehicle = {...vehicle}
    newVehicle[position] = value;
    setVehicle(newVehicle);
  }
  const save = async (event) => {
    event.preventDefault();
    if(searchParams.get('vehicleId')) {
      await updateInspectionsVehicles(vehicle);
      return history(`/fleet/${fleetId}`);
    }
    await createInspectionsVehicles(vehicle);
    history(`/fleet/${fleetId}`);
  };
  useEffect(() => {
    fetchAll();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire - Crear Vehículo</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="/img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>Crear Vehículo</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => history(-1)} className="close-header close-al" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="">
                <form onSubmit={save}>
                  <div className="container-form">
                      <div className="title-h2">
                          <h2>Datos del vehículo</h2>
                          <div className="line">&nbsp;</div>
                      </div>
                      <div className="field-modal container-input">
                        <label>Placa</label>
                              <input type="text" value={vehicle.license_plate} onChange={(event) => setData('license_plate', event.target.value)} className="input-class text-center w-100" required placeholder="Placa"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Kilometraje actual del vehículo</label>
                              <input type="number" value={vehicle.km_initial} onChange={(event) => setData('km_initial', event.target.value)} className="input-class text-center w-100" required placeholder="Kilometraje actual del vehículo"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Milimetraje de retiro</label>
                              <input type="number" value={vehicle.mm_retirement} onChange={(event) => setData('mm_retirement', event.target.value)} className="input-class text-center w-100" required placeholder="Milimetraje de retiro"/>
                      </div>
                      <div className="field-modal container-input">
                      <label>Segmento</label>
                        <select value={vehicle.cvt_inspections_segment_id} required onChange={(event) => setData('cvt_inspections_segment_id', event.target.value)} className="input-class text-center">
                              <option>*Segmento</option>
                              {segments.map((segment) => {
                                  return(<option key={segment.id} value={segment.id}>{segment.description}</option>)
                              })}
                        </select>
                      </div>
                      <div className="field-modal container-input">
                      <label>Marca</label>
                        <select value={vehicle.cvt_inspections_vehicle_brand_id} required onChange={(event) => setData('cvt_inspections_vehicle_brand_id', event.target.value)} className="input-class text-center">
                              <option>*Marca</option>
                              {brands.map((brand) => {
                                  return(<option key={brand.id} value={brand.id}>{brand.description}</option>)
                              })}
                        </select>
                      </div>
                      <div className="field-modal container-input">
                      <label>Configuración</label>
                        <select value={vehicle.cvt_inspections_configuration_id} required onChange={(event) => setData('cvt_inspections_configuration_id', event.target.value)} className="input-class text-center">
                              <option>*Configuración</option>
                              {configurations.map((configuration) => {
                                  return(<option key={configuration.id} value={configuration.id}>{configuration.description}</option>)
                              })}
                        </select>
                      </div>
                      <div className="field-modal container-input">
                      <label>Tipo</label>
                        <select value={vehicle.cvt_inspections_type_id} required onChange={(event) => setData('cvt_inspections_type_id', event.target.value)} className="input-class text-center">
                              <option>*Tipo</option>
                              {types.map((type) => {
                                  return(<option key={type.id} value={type.id}>{type.description}</option>)
                              })}
                        </select>
                      </div>
                      <div className="field-modal container-input">
                      <label>Aplicación</label>
                        <select value={vehicle.application_id} required onChange={(event) => setData('application_id', event.target.value)} className="input-class text-center">
                              <option>*Aplicación</option>
                              {applications.map((application) => {
                                  return(<option key={application.id} value={application.id}>{application.description}</option>)
                              })}
                        </select>
                      </div>

                  </div> 
                  <div className="container-input">
                  <button type="submit" className="btn-action button-init">
                      <span>Guardar</span>
                  </button>
              </div>
                </form>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {InspectionsVehicleForm};