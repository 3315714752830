import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {createFleets} from './apiFleets'
import {Helmet} from 'react-helmet';
import {getCities} from '../City/apiCities';
import {getLocalStorage} from '../LocalStorage/localStorageProvider';

const FleetForm = () => {
  const user = getLocalStorage('user');
  const history = useNavigate();

  const [fleet, setFleet] = useState({description: null});
  const [cities, setCities] = useState([]);
  const setData = (position, value) => {
    const newFleet = {...fleet}
    newFleet[position] = value;
    setFleet(newFleet);
  }
  const save = async (event) => {
    event.preventDefault();
    await createFleets(fleet);
    history('/fleets');
  };
  const fetchCities = async () => {
    const _cities_ = await getCities({country_id: user.country_id});
    setCities(_cities_);
  }
  useEffect(() => {
    fetchCities();
}, []);
  return (
    <div className="container-page">
    <Helmet>
        <title>TruckTire -Crear Flota</title>
    </Helmet>
    <div className="header-form">
        <div className="title-icon">
            <img src="./img/icons/icon_inspections_white.svg" className="img-responsive" alt="Fleets icon"/>
            <span>Crear Flota</span>
        </div>
        <div className="header-callaction">
            <button onClick={() => history(-1)} className="close-header close-al" id="button-close">
                <img src="./img/icons/icon_close.svg" alt="close" />
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="">
                <form onSubmit={save}>
                  <div className="container-form">
                      <div className="title-h2">
                          <h2>Datos de la flota</h2>
                          <div className="line">&nbsp;</div>
                      </div>
                      <div className="field-modal container-input">
                              <input type="text" value={fleet.description} onChange={(event) => setData('description', event.target.value)} className="input-class text-center w-100" required placeholder="Nombre de la flota"/>
                      </div>
                      <div className="field-modal container-input">
                      <select required value={fleet.city_id} onChange={(event) => setData('city_id', event.target.value)} className="input-class text-center">
                              <option>*Ciudad</option>
                              {cities.map((city) => {
                                  return(<option key={city.id} value={city.id}>{city.description}</option>)
                              })}
                        </select>
                      </div>
                  </div> 
                  <div className="container-input">
                  <button type="submit" className="btn-action button-init">
                      <span>Guardar</span>
                  </button>
              </div>
                </form>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}
export {FleetForm};