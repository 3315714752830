import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {getProducts} from "./apiProducts";
import {baseUrlImages} from '../config';
import {
  Scene,
  Color,
  Fog,
  PerspectiveCamera,
  WebGLRenderer,
  DirectionalLight,
  HemisphereLight,
  Vector3,
  Clock,
  Mesh,
  MeshPhongMaterial,

  PlaneGeometry,
} from "three";
import OrbitControls from "three-orbitcontrols";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { setCart, removeCart, getCart, existsInCart } from "../Quoter/quoterProvider";
import { getDatabooksPdf } from "../Databook/apiDatabooks";
import {Helmet} from 'react-helmet';

const Product = () => {
  let container;
  let camera;
  let renderer;
  let scene;
  let controls;

  const mixers = [];
  const clock = new Clock();
  const init = (product) => {
    container = document.querySelector("#scene-container");
  
    scene = new Scene();
    createCamera();
    createLights();
    loadModels(product);
    createControls();
    createRenderer();
  
    renderer.setAnimationLoop(() => {
      update();
      render();
    });
  }

  const createCamera = () => {
    const fov = 20;
    const aspect = container.clientWidth / container.clientHeight;
    const near = 0.5;
    const far = 1000;
    camera = new PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 0.5, 2.5);
  }
  
  const createLights = () => {
    const mainLight = new DirectionalLight(0xffffff, 10);
    mainLight.position.set(10, 20, 10);
    const main2 = new DirectionalLight(0xffffff, 10);
    main2.position.set(-45, 45, 10);
  
    const hemisphereLight = new HemisphereLight(0xddeeff, 0x202020, 10);
    scene.add(mainLight, hemisphereLight, main2);
  }
  
  const loadModels = (product) => {
  console.log("🚀 ~ file: Product.js ~ line 70 ~ loadModels ~ product", product);
    const loader = new GLTFLoader();
  
    const onLoad = (result, position) => {
      const model = result.scene.children[0];
      model.position.copy(position);
      // model.scale.set(1, 1, 1);
      model.rotation.x = 0;
      model.rotation.y = 49.5;
      model.rotation.z = 0;
  
      scene.add(model);
    };
  
    const onProgress = progress => {console.log(progress)};
    const tire = new Vector3(0, 0, 0);
    loader.load(
      `/renders/${product.render_web}.glb`,
      gltf => onLoad(gltf, tire),
      onProgress
    );
  }
  
  const createRenderer = () => {
    renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.gammaFactor = 2.2;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    renderer.physicallyCorrectLights = true;
  
    container.appendChild(renderer.domElement);
  }
  
  const createControls = () => {
    controls = new OrbitControls(camera, container);
    controls.target.set( 0, 0, 0 );
    controls.update();
    controls.enablePan = true;
    controls.enableDamping = true;
  }
  const update = () => {
    const delta = clock.getDelta();
    mixers.forEach(mixer => mixer.update(delta));
  }
  
  const render = () => {
    renderer.render(scene, camera);
  }
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const history = useNavigate();
  const [products, setProducts] = useState([]);
  const [mesures, setMesures] = useState([]);
  const [mesurePicked, setMesurePicked] = useState({});
  let product = {characteristics: [], cvt_databooks: []};
  if(products.length) {
    product = products[0];
  }
  const fetchProducts = async () => {
    const fetchedProducts = await getProducts({id, includeAll: true});
    if(fetchedProducts[0].render_web) {
      init(fetchedProducts[0]);
    }
    setProducts(fetchedProducts)
  }
  const downloadPdf = async () => {
    const ids = mesures.map((mesure) => mesure.id)
    await getDatabooksPdf(ids , product.description);
  }
  const pushMesure = () => {
    const exists = mesures.find(mesure => mesure.id === mesurePicked.id);
    const newMesures = [...mesures];
    if(!exists) {
      newMesures.push(mesurePicked);
      setMesures(newMesures);
    }
  }
  const removeMesure = (id) => {
    const newMesures = mesures.filter(mesure => mesure.id !== id);
      setMesures(newMesures);
  }
  const setMesure = (mesureId) => {
    if(product && product.cvt_databooks.length > 0) {
      const mesure = product.cvt_databooks.find(mesure => mesure.id.toString() === mesureId.toString())
      setMesurePicked(mesure);
    }
  }

  useEffect(() => {
    fetchProducts();
}, []);
  return (
    <div>
      <Helmet>
          <title>TruckTire - {product?.description || ''}</title>
      </Helmet>
    <div className="container-page">
    <div className="header-form">
    <div className="title-icon-continental">
                <img src={`/img/icon_${product.brand_id}.jpg`} className="img-responsive" alt="Cart"/>
                <span>{product.description}</span>
            </div>
            <button onClick={() => history(-1)}className="btn-action border-100 button-init small-icon">
                <span>Cerrar</span>
                <img src="/img/icons/icon_close.svg" className="img-responsive" alt="Cart"/>
            </button>
    </div>
    <div className="container-fluid container-information scroll-object">
    <div>
        <div className="row">
            <div className="col-12 col-sm-7">
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Características</h2>
                        <div className="line">&nbsp;</div>
                    </div>

                      {product.characteristics.map((characteristic) => {
                        return(<p key={characteristic.id}><strong>&#x26AC;</strong> {characteristic.description}</p>)
                      })}

                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Aplicaciones</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <p>
                        {product.application_detail}
                    </p>
                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Generar ficha técnica</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    <div className="container-input margin-top-input">
                        <select onChange={(event) => setMesure(event.target.value)} className="input-class text-center">
                            <option>*Medida</option>
                            {product.cvt_databooks.map((databook) => {
                              return(<option key={databook.id} value={databook.id}>{databook.mesure}</option>)
                            })}
                        </select>
                        <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                    </div>
                    <div className="content-modal-button">
                        <button onClick={() => pushMesure()} className="btn-action border-100 button-init">
                            <span>Seleccionar llanta</span>
                            <img src="/img/icons/icon_plus.svg" className="img-responsive" alt="Cart"/>
                        </button>
                    </div>
                </div>
                <div className="content-information">
                    <div className="title-h2">
                        <h2>Medidas seleccionadas</h2>
                        <div className="line">&nbsp;</div>
                    </div>
                    {mesures.map((mesure) => {
                      mesure.productDescription = product.description;
                      mesure.productPicture = product.picture;
                      mesure.product = {type: product.type};
                      return(
                        <div key={mesure.id} className="content-add-cart">
                        <p>{mesure.mesure} - ({mesure.code})</p>
                        <div className="icons-cart">
                            {!existsInCart(mesure.id) && <a onClick={() => setCart(mesure)} href="#"><img src="/img/icons/icon_cart_plus_dark.svg" className="img-responsive" alt="Cart"/></a>}
                            <a href="#" onClick={() => removeMesure(mesure.id)}><img src="/img/icons/icon_close_red.svg" className="img-responsive" alt="remove"/></a>
                        </div>
                    </div>
                      )
                    })
                    }
                    <div className="content-modal-button">
                        <button onClick={() => downloadPdf()} className="btn-action border-100 button-init">
                            <span>Descargar ficha técnica</span>
                            <img src="/img/icons/icon_download.svg" className="img-responsive" alt="Cart"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-5">
              {!product.render_web &&
                <div className="img-tire img-tire-modal">
                    <img src={`${baseUrlImages}/${product.picture}`} alt="" className="img-responsive" />
                </div>
              }

              <div id="scene-container" style={{marginTop: '50px', width: '100%', height: '500px', cursor: 'grab'}}></div>

            </div>
        </div>
    </div>
    </div>
  </div>
  </div>
  );
}
export {Product};