const hostApi =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/api'
    : 'https://contitools.com/api';
const baseURLApi = `${hostApi}`;
export const baseUrlImages =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/downloads'
    : 'https://contitools.com/downloads';

const config = {
  hostApi,
  baseURLApi,
  baseUrlImages
}
export default config;
